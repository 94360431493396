/* -------------------------------------------------------------------------- */
/*                                   Navbar                                   */
/* -------------------------------------------------------------------------- */

.navbar-toggler:focus {
	box-shadow: none;
}

/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  font-weight: $font-weight-normal;
  font-size: $navbar-font-size;
  letter-spacing: 0.01em;
  border-bottom: $border-width solid rgba($white, 0.15);
  min-height: 3.125rem;
}
.navbar-brand-i {
  background-color: rgba($white, 0.3);
  letter-spacing: 0;
  padding: 0.25rem 0.625rem;
  margin: 0 0.3125rem;
  border-radius: 0.125rem;
  display: inline-block;
}
.nav-link {
  position: relative;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  line-height: 1.2 !important;
}
.navbar-theme {
  background-color: rgba($white, 0);
  .navbar-nav{
    > .nav-item{
      > .nav-link{
        position: relative;
        transition: padding 0.4s ease;
        // padding: 0 0.5rem;
      }
      .nav-link-text{
        padding: 0.0625rem 0.25rem;
        transition: $transition-base;
      }
      > .nav-link:active,
      > .nav-link.active,
      > .nav-link:hover ,
      &.show > .nav-link {
      > .nav-link-text{
          background-color: $white;
          color: $black;
          border-radius: 0.125rem;
        }
      }
    }
  }
}
.navbar-theme .navbar-collapse.show{      
  max-height: calc(100vh - 4.063rem);
  overflow-y: auto;
}
.dropdown-components{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  @include media-breakpoint-up(lg){
    min-width: 37rem;
  }
}
.dropdown-pages{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  @include media-breakpoint-up(lg){
    min-width: 24rem;
  }
}


@each $breakpoint in map_keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .navbar-expand-#{$breakpoint} {
      .navbar-theme{
        min-height: 4rem;
      }
      .navbar-brand{
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
      }
      .navbar-collapse.show{
        overflow: visible;
        max-height: none;
      }
      .navbar-nav > .nav-item{
        > .nav-link{
          padding: 1.375rem 1.625rem;
        }
      }
    }
  }
}
