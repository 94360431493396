/*-----------------------------------------------
|   Buttons
-----------------------------------------------*/
.btn{ 
  text-transform: uppercase; 
  transition: $transition-base;
}

.btn-group-sm>.btn,.btn-sm {
  padding: .25rem 1.5rem;
  font-size: .72888rem;
  line-height: 1.273;
  border-radius: .1875rem;
}

.btn-link{
  font-weight: $font-weight-bold; 
  text-transform: none;
}
.btn.btn-outline-light.border-2x { border-color: rgba($white, 0.5); }
.btn-outline-light:hover, 
.btn-light:hover {
  color: $dark; 
  background-color: #e2e2e2;
  border-color: #dcdcdc;
}


.btn-xs{ padding: .2rem 1rem; font-size: $font-size-sm; }

.btn-play {
  color: $white;
  border: 1px solid $white;
  width: 3.75rem;
  height: 3.75rem;
  position: relative;
  display: block;
  line-height: 3.75rem;
  border-radius: $border-radius;

  @include hover-focus {
    color: $black !important;
  }
}
.btn-dark {
  color: $gray-300;
}
.btn-back-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 999;
  background: #eee;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  line-height: 1.75rem;
  font-size: 0.875rem;
  color: #000;
  opacity: 0.6;
  border-radius: 2px;
}

.btn-back-to-top:hover {
  color: #000;
  opacity: 1;
}

