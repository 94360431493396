.swiper-testimonial-container{
  .swiper-nav{
    margin-top: 0;
    cursor: pointer;
    .swiper-button-prev , .swiper-button-next{ 
      font-weight: $font-weight-bolder;
      position: absolute;
      transform: translateY(-50%);
      transition: opacity 0.4s ease-in-out;
      color: var(--#{$variable-prefix}700);
      z-index: 1;
      margin: 0;
      @include hover-focus {
        color: var(--#{$variable-prefix}white);
      }
    }
    .swiper-button-next{
      right: map_get($spacers, 8); 
      top:73%;
    }
    .swiper-button-prev { 
      left: map_get($spacers, 8);
      top:73%;
    }

  }
  
  // @include hover-focus { .swiper-nav { [class*=swiper-] { opacity: 0.5; } } }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 1.2rem;
    font-weight: $font-weight-bolder;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}

.news-slider{
  .swiper-pagination-bullet{
    width: 0.625rem;
    height: 0.625rem;
    background: #D6D6D6;
    margin: 0.313rem 0.438rem !important;
    opacity: 1;

    @include hover-focus {
      background: $primary;
    }

    &.swiper-pagination-bullet-active{
      background: $primary;
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets, 
  .swiper-pagination-bullets.swiper-pagination-horizontal, 
  .swiper-pagination-custom, 
  .swiper-pagination-fraction{
    bottom: 0;
  }
}

.header-classic{
  height: 40.438rem;

  @include media-breakpoint-up(sm) {
    min-height: 32.313rem;
    height: auto;
  }
}



.testimonial-avatar {
  position: absolute;
  width: 6.25rem !important;
  height: 6.25rem;
  left: 50%;
  transform: translate(-50%, -127%);
  background-size: cover;
  backface-visibility: hidden;
  transition: all .4s ease-in;
	background-repeat: no-repeat;
}

.testimonial-quote {
  position: relative;
  line-height: 100%;
  top: 0;
  left: 0;

  &:before {
    position: absolute;
    content: '“';
    height: 2rem;
    width: 2rem;
    top: 2.3rem;
    color: #fff;
    font-size: 8rem;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-light;
    opacity: 0.5;
    left: 50%;
    transform: translateX(-50%);
  }
}
.testimonial {
  .owl-theme.owl-nav-outer .owl-nav{
    margin-top: 0;
    cursor: pointer;
    [class*=owl-] {
      opacity: 0.5;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      color: $white !important;
      font-size: 1.5rem !important;
      background-color: transparent !important;
      box-shadow: none;
      @include hover-focus {
        color: $white;
        opacity: 1 !important;
      }
    }
    .owl-prev{ left: - map_get($spacers, 9); }
    .owl-next{ right: - map_get($spacers, 9); }
  }
  .owl-dots{
    bottom: -7.5rem;
  }
}
.testimonial-company-logo{
  width: 6.25rem !important;
  display: inline-block;
  opacity: .5;
}
@include media-breakpoint-up(md) {
  
  .testimonial-quote::before {
    left: -2.5rem;
    transform: initial;
    top: 2rem;
  }
}