.theme-slider{
  .swiper-nav{
    margin-top: 0;
    cursor: pointer;
    [class*=swiper-] {
      opacity: 0;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      transition: opacity 0.4s ease-in-out;
      color: var(--#{$variable-prefix}100);
      z-index: 1;
      margin: 0;
      @include hover-focus {
        color: var(--#{$variable-prefix}white);
        opacity: 1 !important;
      }
    }
    .swiper-button-prev{ 
      left: map_get($spacers, 2);
    }
    .swiper-button-next{ right: map_get($spacers, 2); }
  }
  
  @include hover-focus { .swiper-nav { [class*=swiper-] { opacity: 0.5; } } }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 1.5rem;
    font-weight: $font-weight-medium;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}

.product-slider{
  position: relative;
  @include media-breakpoint-up(lg){
    height: calc(100% - 3.5625rem);
  }

  @include media-breakpoint-up(xl){
    height: calc(100% - 3.3125rem);
  }

  @include media-breakpoint-up(xxl){
    height: calc(100% - 4.75rem);
  }
}


.header-slider{
  height: 100vh;
  width:100%;
  filter: brightness(.7);
  object-fit: cover;
}
.slider-classic{
  height: 50vh;
  width:100%;
  filter: brightness(.99);
  object-fit: cover;
}
.header-classic-slider{
  height: 50vh;
  width:100%;
  filter: brightness(.7);
  object-fit: cover;
}

.swiper-container{
  @include media-breakpoint-up(lg) {
    .swiper-nav{
      margin-top: 0;
      cursor: pointer;
      .swiper-button-prev , .swiper-button-next{ 
        opacity: 0;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        transition: opacity 0.4s ease-in-out;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        color: var(--#{$variable-prefix}100);
        border: 1px solid $white;
        box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
        z-index: 1;
        margin: 0;
        @include hover-focus {
          color: var(--#{$variable-prefix}white);
          opacity: 1 !important;
        }
      }
      .swiper-button-next{
        right: -(map_get($spacers, 3)); 
      }
      .swiper-button-prev { 
        left: -(map_get($spacers, 3));
      }

    }
    
    @include hover-focus { .swiper-nav { [class*=swiper-] { opacity: 0.5; } } }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-family: swiper-icons;
      font-size: 1.5rem;
      font-weight: $font-weight-medium;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }
}

.swiper-container.swiper-clients{
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
}


.swiper-pagination-bullet{
  margin: map-get($spacers, 2 );
  background: rgba($black, 0.5) !important;
  border: 1px solid $white;
  font-size: 1rem;
  &:focus{
    outline: unset;
  }
}
.swiper-pagination-bullet-active{
  background: $white !important;
}
.swiper-pagination{
  margin-top: map-get($spacers, 3 );
  position: unset !important;
  text-align: center;
}