@charset "UTF-8";
/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */
:root {
  --reign-blue: #007bff;
  --reign-indigo: #6610f2;
  --reign-purple: #6f42c1;
  --reign-pink: #e83e8c;
  --reign-red: #dc3545;
  --reign-orange: #fd7e14;
  --reign-yellow: #ffc107;
  --reign-green: #28a745;
  --reign-teal: #20c997;
  --reign-cyan: #17a2b8;
  --reign-white: #fff;
  --reign-gray: #9ba0a7;
  --reign-gray-dark: #5f656c;
  --reign-gray-black: #000;
  --reign-gray-100: #f5f5f5;
  --reign-gray-200: #ecedee;
  --reign-gray-300: #dadcdf;
  --reign-gray-400: #c8cbcf;
  --reign-gray-500: #b6babf;
  --reign-gray-600: #9ba0a7;
  --reign-gray-700: #777e87;
  --reign-gray-800: #5f656c;
  --reign-gray-900: #4f545a;
  --reign-gray-1000: #373b3f;
  --reign-gray-1100: #17191b;
  --reign-gray-white: #fff;
  --reign-primary: #007bff;
  --reign-secondary: #777e87;
  --reign-success: #28a745;
  --reign-info: #17a2b8;
  --reign-warning: #ffc107;
  --reign-danger: #dc3545;
  --reign-light: #f5f5f5;
  --reign-dark: #17191b;
  --reign-primary-rgb: 0, 123, 255;
  --reign-secondary-rgb: 119, 126, 135;
  --reign-success-rgb: 40, 167, 69;
  --reign-info-rgb: 23, 162, 184;
  --reign-warning-rgb: 255, 193, 7;
  --reign-danger-rgb: 220, 53, 69;
  --reign-light-rgb: 245, 245, 245;
  --reign-dark-rgb: 23, 25, 27;
  --reign-white-rgb: 255, 255, 255;
  --reign-black-rgb: 0, 0, 0;
  --reign-body-color-rgb: 95, 101, 108;
  --reign-body-bg-rgb: 255, 255, 255;
  --reign-font-sans-serif: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --reign-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --reign-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --reign-body-font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --reign-body-font-size: 0.875rem;
  --reign-body-font-weight: 400;
  --reign-body-line-height: 1.5;
  --reign-body-color: #5f656c;
  --reign-body-bg: #fff;
}

:root, :root.light, :root .light {
  --reign-facebook: #3b5998;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-facebook-hover-color: #2f477a;
  --reign-google-plus: #db4437;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-google-plus-hover-color: #af362c;
  --reign-twitter: #1da1f2;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-twitter-hover-color: #1781c2;
  --reign-linkedin: #0077b5;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-linkedin-hover-color: #005f91;
  --reign-youtube: #ff0001;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-youtube-hover-color: #cc0001;
  --reign-github: #24292e;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-github-hover-color: #1d2125;
  --reign-black: #000;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-black-hover-color: black;
  --reign-100: #f5f5f5;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-100-hover-color: #f7f7f7;
  --reign-200: #ecedee;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-200-hover-color: #f0f1f1;
  --reign-300: #dadcdf;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-300-hover-color: #e1e3e5;
  --reign-400: #c8cbcf;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-400-hover-color: #d3d5d9;
  --reign-500: #b6babf;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-500-hover-color: #c5c8cc;
  --reign-600: #9ba0a7;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-600-hover-color: #7c8086;
  --reign-700: #777e87;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-700-hover-color: #5f656c;
  --reign-800: #5f656c;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-800-hover-color: #4c5156;
  --reign-900: #4f545a;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-900-hover-color: #3f4348;
  --reign-1000: #373b3f;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-1000-hover-color: #2c2f32;
  --reign-1100: #17191b;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-1100-hover-color: #121416;
  --reign-white: #fff;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-white-hover-color: white;
  --reign-primary: #007bff;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-primary-hover-color: #0062cc;
  --reign-secondary: #777e87;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-secondary-hover-color: #5f656c;
  --reign-success: #28a745;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-success-hover-color: #208637;
  --reign-info: #17a2b8;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-info-hover-color: #128293;
  --reign-warning: #ffc107;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-warning-hover-color: #ffcd39;
  --reign-danger: #dc3545;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-danger-hover-color: #b02a37;
  --reign-light: #f5f5f5;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-light-hover-color: #f7f7f7;
  --reign-dark: #17191b;
  /* ------------------------------ Colored Link ------------------------------ */
  --reign-colored-link-dark-hover-color: #121416;
  --reign-body-bg: #fff;
  --reign-body-color: #5f656c;
  --reign-headings-color: #007bff;
  --reign-link-color: #007bff;
  --reign-link-hover-color: #0062cc;
  --reign-border-color: #dadcdf;
  /* --------------------------------- Shadow --------------------------------- */
  --reign-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  --reign-box-shadow-sm: 0 0 1.563rem rgba(0, 0, 0, 0.08);
  --reign-box-shadow-lg: 0 1rem 4rem rgba(0, 0, 0, 0.175);
  --reign-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --reign-component-active-color: ;
  --reign-component-active-bg: #007bff;
  --reign-muted: #b6babf;
  --reign-blockquote-footer-color: #9ba0a7;
  --reign-hr-color: var(--reign-border-color);
  --reign-hr-opacity: 0.25;
  --reign-mark-bg: #fcf8e3;
  --reign-input-btn-focus-color: rgba(0, 123, 255, 0.25);
  --reign-btn-link-color: #007bff;
  --reign-btn-link-hover-color: #0062cc;
  --reign-btn-disabled-color: #9ba0a7;
  --reign-heading-color: #4f545a;
  /* ---------------------------------- Form ---------------------------------- */
  --reign-input-bg: #fff;
  --reign-input-color: #4f545a;
  --reign-input-border-color: #dadcdf;
  --reign-input-focus-border-color: #80bdff;
  --reign-input-focus-color: #4f545a;
  --reign-input-placeholder-color: #c8cbcf;
  --reign-input-plaintext-color: #5f656c;
  --reign-form-check-label-color: ;
  --reign-form-check-input-bg: #fff;
  --reign-form-check-input-border: 1px solid rgba(0, 0, 0, 0.25);
  --reign-form-check-input-border-color: #c8cbcf;
  --reign-form-check-input-checked-color: #fff;
  --reign-form-check-input-checked-bg-color: #007bff;
  --reign-form-check-input-checked-border-color: #007bff;
  --reign-form-check-input-indeterminate-color: #fff;
  --reign-form-check-input-indeterminate-bg-color: #007bff;
  --reign-form-check-input-indeterminate-border-color: #007bff;
  --reign-form-switch-color:rgba(0, 0, 0, .25);
  --reign-form-switch-focus-color: #80bdff;
  --reign-form-switch-checked-color: #fff;
  --reign-input-group-addon-color: #dadcdf;
  --reign-input-group-addon-bg: #ecedee;
  --reign-input-group-addon-border-color: #dadcdf;
  --reign-form-select-color: #4f545a;
  --reign-form-select-disabled-color: #9ba0a7;
  --reign-form-select-bg: #fff;
  --reign-form-select-disabled-bg: #ecedee;
  --reign-form-select-indicator-color: #5f656c;
  --reign-form-select-focus-box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  --reign-form-select-border-color: #dadcdf;
  --reign-form-select-focus-border-color: #80bdff;
  --reign-form-range-track-bg: #dadcdf;
  --reign-form-range-track-box-shadow: var(--reign-box-shadow-inset);
  --reign-form-range-thumb-bg: #007bff;
  --reign-form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  --reign-form-range-thumb-focus-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  --reign-form-range-thumb-active-bg: #b3d7ff;
  --reign-form-range-thumb-disabled-bg: #b6babf;
  --reign-form-file-focus-border-color: #80bdff;
  --reign-form-file-focus-box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  --reign-form-file-disabled-bg: #ecedee;
  --reign-form-file-disabled-border-color: ;
  --reign-form-file-color: #4f545a;
  --reign-form-file-bg: #fff;
  --reign-form-file-border-color: #dadcdf;
  --reign-form-file-box-shadow: 0 0 0 transparent;
  --reign-form-file-button-bg: #ecedee;
  --reign-form-feedback-valid-color: #28a745;
  --reign-form-feedback-invalid-color: #dc3545;
  --reign-form-feedback-icon-valid-color: var(--reign-form-feedback-valid-color);
  --reign-form-feedback-icon-invalid-color: var(--reign-form-feedback-invalid-color);
  /* ----------------------------------- Nav ---------------------------------- */
  --reign-bg-navbar-glass: rgba(255, 255, 255, 0.85);
  /* ---------------------------------- Badge --------------------------------- */
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-primary-background-color: #cce5ff;
  --reign-badge-soft-primary-color: #004fa3;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-secondary-background-color: #e4e5e7;
  --reign-badge-soft-secondary-color: #80868f;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-success-background-color: #d4edda;
  --reign-badge-soft-success-color: #1a6b2c;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-info-background-color: #d1ecf1;
  --reign-badge-soft-info-color: #0f6876;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-warning-background-color: #fff3cd;
  --reign-badge-soft-warning-color: #a37c04;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-danger-background-color: #f8d7da;
  --reign-badge-soft-danger-color: #8d222c;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-light-background-color: #fdfdfd;
  --reign-badge-soft-light-color: #9d9d9d;
  /* ---------------------------------- Badge --------------------------------- */
  --reign-badge-soft-dark-background-color: #d1d1d1;
  --reign-badge-soft-dark-color: #0f1011;
  /* -------------------------------- Dropdown -------------------------------- */
  --reign-dropdown-bg: rgba(23, 25, 27, 0.95);
  --reign-dropdown-color: #dadcdf;
  --reign-dropdown-border-color: var(--reign-border-color);
  --reign-dropdown-box-shadow: var(--reign-box-shadow-sm);
  --reign-dropdown-link-color: #17191b;
  --reign-dropdown-link-hover-color: #0b0c0d;
  --reign-dropdown-link-hover-bg: null;
  --reign-dropdown-link-active-color: #fff;
  --reign-dropdown-link-active-bg: null;
  --reign-dropdown-link-disabled-color: #9ba0a7;
  --reign-dropdown-header-color: #9ba0a7;
  --reign-dropdown-dark-color: #dadcdf;
  --reign-dropdown-dark-bg: #5f656c;
  --reign-dropdown-dark-border-color: rgba(0, 0, 0, 0.15);
  --reign-dropdown-dark-divider-bg: rgba(255, 255, 255, 0.5);
  --reign-dropdown-dark-box-shadow: null;
  --reign-dropdown-dark-link-color: #dadcdf;
  --reign-dropdown-dark-link-hover-color: #fff;
  --reign-dropdown-dark-link-hover-bg: rgba(255, 255, 255, 0.15);
  --reign-dropdown-dark-link-active-color: #fff;
  --reign-dropdown-dark-link-active-bg: #000;
  --reign-dropdown-dark-link-disabled-color: #b6babf;
  --reign-dropdown-dark-header-color: #b6babf;
  /* ------------------------------- Pagination ------------------------------- */
  --reign-pagination-color: #9ba0a7;
  --reign-pagination-bg: #fff;
  --reign-pagination-border-color: #dadcdf;
  --reign-pagination-hover-color: #fff;
  --reign-pagination-hover-bg: #000;
  --reign-pagination-hover-border-color: #000;
  --reign-pagination-active-color: #fff;
  --reign-pagination-active-bg: #000;
  --reign-pagination-active-border-color: #000;
  /* ---------------------------------- Card ---------------------------------- */
  --reign-card-border-color: #ecedee;
  --reign-card-cap-bg: rgba(0, 0, 0, 0);
  --reign-card-cap-color: null;
  --reign-card-color: null;
  --reign-card-bg: #fff;
  /* -------------------------------- Accordion ------------------------------- */
  --reign-accordion-border-color: rgba(0, 0, 0, 0.125);
  --reign-accordion-button-active-color: var(--reign-1000);
  --reign-accordion-button-active-bg: $white;
  /* --------------------------------- Swiper --------------------------------- */
  --reign-swiper-nav-bg: rgba(255, 255, 255, 0.8);
  /* ---------------------------------- Toast --------------------------------- */
  --reign-toast-background-color: rgba(255, 255, 255, 0.85);
  /* -------------------------------- Scrollbar ------------------------------- */
  --reign-scrollbar-bg: rgba(200, 203, 207, 0.55);
  /* ------------------------------- Hover Background ------------------------------ */
  --reign-hover-bg-black: #000;
  --reign-hover-bg-100: #f5f5f5;
  --reign-hover-bg-200: #ecedee;
  --reign-hover-bg-300: #dadcdf;
  --reign-hover-bg-400: #c8cbcf;
  --reign-hover-bg-500: #b6babf;
  --reign-hover-bg-600: #9ba0a7;
  --reign-hover-bg-700: #777e87;
  --reign-hover-bg-800: #5f656c;
  --reign-hover-bg-900: #4f545a;
  --reign-hover-bg-1000: #373b3f;
  --reign-hover-bg-1100: #17191b;
  --reign-hover-bg-white: #fff;
  /* ------------------------------- Custom disabled button ------------------------------ */
  --reign-btn-disabled-custom-background: rgba(245, 245, 245, 0.65);
  --reign-btn-disabled-custom-color: rgba(23, 25, 27, 0.3);
}

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--reign-body-font-family);
  font-size: var(--reign-body-font-size);
  font-weight: var(--reign-body-font-weight);
  line-height: var(--reign-body-line-height);
  color: var(--reign-body-color);
  text-align: var(--reign-body-text-align);
  background-color: var(--reign-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.25rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.625rem;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 200;
  line-height: 3.125rem;
  color: #000;
}

h1, .h1 {
  font-size: calc(1.373832rem + 1.485984vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.48832rem;
  }
}

h2, .h2 {
  font-size: calc(1.33236rem + 0.98832vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.0736rem;
  }
}

h3, .h3 {
  font-size: calc(1.2978rem + 0.5736vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.728rem;
  }
}

h4, .h4 {
  font-size: calc(1.269rem + 0.228vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.44rem;
  }
}

h5, .h5 {
  font-size: 1.2rem;
}

h6, .h6 {
  font-size: 0.7291666667rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-size: 75%;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
}
a:hover {
  color: #0062cc;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--reign-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 75%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 75%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 75%;
  color: #fff;
  background-color: #4f545a;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #b6babf;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.166375rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.554981696rem + 3.659780352vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.29981696rem;
  }
}

.display-2 {
  font-size: calc(1.48331808rem + 2.79981696vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.5831808rem;
  }
}

.display-3 {
  font-size: calc(1.4235984rem + 2.0831808vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.985984rem;
  }
}

.display-4 {
  font-size: calc(1.373832rem + 1.485984vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.48832rem;
  }
}

.display-5 {
  font-size: calc(1.33236rem + 0.98832vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.0736rem;
  }
}

.display-6 {
  font-size: calc(1.2978rem + 0.5736vw);
  font-weight: 200;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.728rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 75%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1.166375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;
  font-size: 75%;
  color: #9ba0a7;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dadcdf;
  border-radius: 0.188rem;
  box-shadow: var(--reign-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.figure-caption {
  font-size: 75%;
  color: #9ba0a7;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--reign-gutter-x, 1.25rem);
  padding-left: var(--reign-gutter-x, 1.25rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  --reign-gutter-x: 2.5rem;
  --reign-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--reign-gutter-y));
  margin-right: calc(-0.5 * var(--reign-gutter-x));
  margin-left: calc(-0.5 * var(--reign-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--reign-gutter-x) * .5);
  padding-left: calc(var(--reign-gutter-x) * .5);
  margin-top: var(--reign-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --reign-gutter-x: 0;
}

.g-0,
.gy-0 {
  --reign-gutter-y: 0;
}

.g-1,
.gx-1 {
  --reign-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --reign-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --reign-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --reign-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --reign-gutter-x: 1.25rem;
}

.g-3,
.gy-3 {
  --reign-gutter-y: 1.25rem;
}

.g-4,
.gx-4 {
  --reign-gutter-x: 2.5rem;
}

.g-4,
.gy-4 {
  --reign-gutter-y: 2.5rem;
}

.g-5,
.gx-5 {
  --reign-gutter-x: 3.75rem;
}

.g-5,
.gy-5 {
  --reign-gutter-y: 3.75rem;
}

.g-6,
.gx-6 {
  --reign-gutter-x: 5rem;
}

.g-6,
.gy-6 {
  --reign-gutter-y: 5rem;
}

.g-7,
.gx-7 {
  --reign-gutter-x: 6.25rem;
}

.g-7,
.gy-7 {
  --reign-gutter-y: 6.25rem;
}

.g-8,
.gx-8 {
  --reign-gutter-x: 7.5rem;
}

.g-8,
.gy-8 {
  --reign-gutter-y: 7.5rem;
}

.g-9,
.gx-9 {
  --reign-gutter-x: 8.75rem;
}

.g-9,
.gy-9 {
  --reign-gutter-y: 8.75rem;
}

.g-10,
.gx-10 {
  --reign-gutter-x: 10rem;
}

.g-10,
.gy-10 {
  --reign-gutter-y: 10rem;
}

.g-11,
.gx-11 {
  --reign-gutter-x: 11.25rem;
}

.g-11,
.gy-11 {
  --reign-gutter-y: 11.25rem;
}

.g-card,
.gx-card {
  --reign-gutter-x: 2.5rem;
}

.g-card,
.gy-card {
  --reign-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --reign-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --reign-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --reign-gutter-x: 0.3125rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --reign-gutter-y: 0.3125rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --reign-gutter-x: 0.625rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --reign-gutter-y: 0.625rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --reign-gutter-x: 1.25rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --reign-gutter-y: 1.25rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --reign-gutter-x: 2.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --reign-gutter-y: 2.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --reign-gutter-x: 3.75rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --reign-gutter-y: 3.75rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --reign-gutter-x: 5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --reign-gutter-y: 5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --reign-gutter-x: 6.25rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --reign-gutter-y: 6.25rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --reign-gutter-x: 7.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --reign-gutter-y: 7.5rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --reign-gutter-x: 8.75rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --reign-gutter-y: 8.75rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --reign-gutter-x: 10rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --reign-gutter-y: 10rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --reign-gutter-x: 11.25rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --reign-gutter-y: 11.25rem;
  }

  .g-sm-card,
.gx-sm-card {
    --reign-gutter-x: 2.5rem;
  }

  .g-sm-card,
.gy-sm-card {
    --reign-gutter-y: 2.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --reign-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --reign-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --reign-gutter-x: 0.3125rem;
  }

  .g-md-1,
.gy-md-1 {
    --reign-gutter-y: 0.3125rem;
  }

  .g-md-2,
.gx-md-2 {
    --reign-gutter-x: 0.625rem;
  }

  .g-md-2,
.gy-md-2 {
    --reign-gutter-y: 0.625rem;
  }

  .g-md-3,
.gx-md-3 {
    --reign-gutter-x: 1.25rem;
  }

  .g-md-3,
.gy-md-3 {
    --reign-gutter-y: 1.25rem;
  }

  .g-md-4,
.gx-md-4 {
    --reign-gutter-x: 2.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --reign-gutter-y: 2.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --reign-gutter-x: 3.75rem;
  }

  .g-md-5,
.gy-md-5 {
    --reign-gutter-y: 3.75rem;
  }

  .g-md-6,
.gx-md-6 {
    --reign-gutter-x: 5rem;
  }

  .g-md-6,
.gy-md-6 {
    --reign-gutter-y: 5rem;
  }

  .g-md-7,
.gx-md-7 {
    --reign-gutter-x: 6.25rem;
  }

  .g-md-7,
.gy-md-7 {
    --reign-gutter-y: 6.25rem;
  }

  .g-md-8,
.gx-md-8 {
    --reign-gutter-x: 7.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --reign-gutter-y: 7.5rem;
  }

  .g-md-9,
.gx-md-9 {
    --reign-gutter-x: 8.75rem;
  }

  .g-md-9,
.gy-md-9 {
    --reign-gutter-y: 8.75rem;
  }

  .g-md-10,
.gx-md-10 {
    --reign-gutter-x: 10rem;
  }

  .g-md-10,
.gy-md-10 {
    --reign-gutter-y: 10rem;
  }

  .g-md-11,
.gx-md-11 {
    --reign-gutter-x: 11.25rem;
  }

  .g-md-11,
.gy-md-11 {
    --reign-gutter-y: 11.25rem;
  }

  .g-md-card,
.gx-md-card {
    --reign-gutter-x: 2.5rem;
  }

  .g-md-card,
.gy-md-card {
    --reign-gutter-y: 2.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --reign-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --reign-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --reign-gutter-x: 0.3125rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --reign-gutter-y: 0.3125rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --reign-gutter-x: 0.625rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --reign-gutter-y: 0.625rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --reign-gutter-x: 1.25rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --reign-gutter-y: 1.25rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --reign-gutter-x: 2.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --reign-gutter-y: 2.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --reign-gutter-x: 3.75rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --reign-gutter-y: 3.75rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --reign-gutter-x: 5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --reign-gutter-y: 5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --reign-gutter-x: 6.25rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --reign-gutter-y: 6.25rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --reign-gutter-x: 7.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --reign-gutter-y: 7.5rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --reign-gutter-x: 8.75rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --reign-gutter-y: 8.75rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --reign-gutter-x: 10rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --reign-gutter-y: 10rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --reign-gutter-x: 11.25rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --reign-gutter-y: 11.25rem;
  }

  .g-lg-card,
.gx-lg-card {
    --reign-gutter-x: 2.5rem;
  }

  .g-lg-card,
.gy-lg-card {
    --reign-gutter-y: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --reign-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --reign-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --reign-gutter-x: 0.3125rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --reign-gutter-y: 0.3125rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --reign-gutter-x: 0.625rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --reign-gutter-y: 0.625rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --reign-gutter-x: 1.25rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --reign-gutter-y: 1.25rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --reign-gutter-x: 2.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --reign-gutter-y: 2.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --reign-gutter-x: 3.75rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --reign-gutter-y: 3.75rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --reign-gutter-x: 5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --reign-gutter-y: 5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --reign-gutter-x: 6.25rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --reign-gutter-y: 6.25rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --reign-gutter-x: 7.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --reign-gutter-y: 7.5rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --reign-gutter-x: 8.75rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --reign-gutter-y: 8.75rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --reign-gutter-x: 10rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --reign-gutter-y: 10rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --reign-gutter-x: 11.25rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --reign-gutter-y: 11.25rem;
  }

  .g-xl-card,
.gx-xl-card {
    --reign-gutter-x: 2.5rem;
  }

  .g-xl-card,
.gy-xl-card {
    --reign-gutter-y: 2.5rem;
  }
}
.table {
  --reign-table-bg: transparent;
  --reign-table-accent-bg: transparent;
  --reign-table-striped-color: #5f656c;
  --reign-table-striped-bg: rgba(0, 0, 0, 0.05);
  --reign-table-active-color: #5f656c;
  --reign-table-active-bg: rgba(0, 0, 0, 0.1);
  --reign-table-hover-color: #5f656c;
  --reign-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1.25rem;
  color: #5f656c;
  vertical-align: top;
  border-color: var(--reign-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--reign-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--reign-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --reign-table-accent-bg: var(--reign-table-striped-bg);
  color: var(--reign-table-striped-color);
}

.table-active {
  --reign-table-accent-bg: var(--reign-table-active-bg);
  color: var(--reign-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --reign-table-accent-bg: var(--reign-table-hover-bg);
  color: var(--reign-table-hover-color);
}

.table-primary {
  --reign-table-bg: #cce5ff;
  --reign-table-striped-bg: #c2daf2;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #b8cee6;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #bdd4ec;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #b8cee6;
}

.table-secondary {
  --reign-table-bg: #e4e5e7;
  --reign-table-striped-bg: #d9dadb;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #cdced0;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #d3d4d6;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #cdced0;
}

.table-success {
  --reign-table-bg: #d4edda;
  --reign-table-striped-bg: #c9e1cf;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #bfd5c4;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #c4dbca;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #bfd5c4;
}

.table-info {
  --reign-table-bg: #d1ecf1;
  --reign-table-striped-bg: #c7e0e5;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #bcd4d9;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #c1dadf;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #bcd4d9;
}

.table-warning {
  --reign-table-bg: #fff3cd;
  --reign-table-striped-bg: #f2e7c3;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #e6dbb9;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #ece1be;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --reign-table-bg: #f8d7da;
  --reign-table-striped-bg: #eccccf;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #dfc2c4;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #e5c7ca;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --reign-table-bg: #f5f5f5;
  --reign-table-striped-bg: #e9e9e9;
  --reign-table-striped-color: #000;
  --reign-table-active-bg: #dddddd;
  --reign-table-active-color: #000;
  --reign-table-hover-bg: #e3e3e3;
  --reign-table-hover-color: #000;
  color: #000;
  border-color: #dddddd;
}

.table-dark {
  --reign-table-bg: #17191b;
  --reign-table-striped-bg: #232526;
  --reign-table-striped-color: #fff;
  --reign-table-active-bg: #2e3032;
  --reign-table-active-color: #fff;
  --reign-table-hover-bg: #282a2c;
  --reign-table-hover-color: #fff;
  color: #fff;
  border-color: #2e3032;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  font-size: 1.166375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 75%;
  color: #b6babf;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f545a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dadcdf;
  appearance: none;
  border-radius: 0.188rem;
  box-shadow: 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #4f545a;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #9ba0a7;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #ecedee;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.625rem;
  margin: -0.375rem -0.625rem;
  margin-inline-end: 0.625rem;
  color: #4f545a;
  background-color: #ecedee;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e0e1e2;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.625rem;
  margin: -0.375rem -0.625rem;
  margin-inline-end: 0.625rem;
  color: #4f545a;
  background-color: #ecedee;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e0e1e2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #5f656c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.875rem + 2px);
  padding: 0.9375rem 0.9375rem;
  font-size: 1.166375rem;
  border-radius: 0.625rem;
}
.form-control-lg::file-selector-button {
  padding: 0.9375rem 0.9375rem;
  margin: -0.9375rem -0.9375rem;
  margin-inline-end: 0.9375rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.9375rem 0.9375rem;
  margin: -0.9375rem -0.9375rem;
  margin-inline-end: 0.9375rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.875rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.188rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.188rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1.875rem 0.375rem 0.625rem;
  -moz-padding-start: calc(0.625rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f545a;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235f656c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.625rem center;
  background-size: 16px 12px;
  border: 1px solid #dadcdf;
  border-radius: 0.188rem;
  box-shadow: var(--reign-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: var(--reign-box-shadow-inset), 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.625rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #ecedee;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4f545a;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-left: 0.9375rem;
  font-size: 1.166375rem;
  border-radius: 0.625rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #007bff;
  border-color: #007bff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dadcdf;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--reign-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dadcdf;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--reign-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #b6babf;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #b6babf;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.75rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.75rem 0.625rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 0.75rem 0.625rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f545a;
  text-align: center;
  white-space: nowrap;
  background-color: #ecedee;
  border: 1px solid #dadcdf;
  border-radius: 0.188rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.9375rem 0.9375rem;
  font-size: 1.166375rem;
  border-radius: 0.625rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3125rem 0.625rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.188rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 3.4375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235f656c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.625rem center, center right 1.875rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #28a745;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3125rem 0.625rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.188rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 3.4375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235f656c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.625rem center, center right 1.875rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #5f656c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 2.188rem;
  font-size: 0.875rem;
  border-radius: 0.188rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #5f656c;
  text-decoration: none;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 0 transparent;
}
.btn-primary:hover {
  color: #fff;
  background-color: #003773;
  border-color: #0062cc;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #003773;
  border-color: #0062cc;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(38, 143, 255, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  color: #fff;
  background-color: #777e87;
  border-color: #777e87;
  box-shadow: 0 0 0 transparent;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #36393d;
  border-color: #5f656c;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #36393d;
  border-color: #5f656c;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(139, 145, 153, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f656c;
  border-color: #595f65;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(139, 145, 153, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #777e87;
  border-color: #777e87;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 0 0 transparent;
}
.btn-success:hover {
  color: #fff;
  background-color: #124b1f;
  border-color: #208637;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #124b1f;
  border-color: #208637;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #208637;
  border-color: #1e7d34;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(72, 180, 97, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 transparent;
}
.btn-info:hover {
  color: #fff;
  background-color: #0a4953;
  border-color: #128293;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #0a4953;
  border-color: #128293;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(58, 176, 195, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #128293;
  border-color: #117a8a;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(58, 176, 195, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 0 0 transparent;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 0 transparent;
}
.btn-danger:hover {
  color: #fff;
  background-color: #63181f;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #63181f;
  border-color: #b02a37;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: 0 0 0 transparent;
}
.btn-light:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(208, 208, 208, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(208, 208, 208, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-dark {
  color: #fff;
  background-color: #17191b;
  border-color: #17191b;
  box-shadow: 0 0 0 transparent;
}
.btn-dark:hover {
  color: #fff;
  background-color: #0a0b0c;
  border-color: #121416;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #0a0b0c;
  border-color: #121416;
  box-shadow: 0 0 0 transparent, 0 0 0 0.25rem rgba(58, 60, 61, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #121416;
  border-color: #111314;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(58, 60, 61, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #17191b;
  border-color: #17191b;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #777e87;
  border-color: #777e87;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #777e87;
  border-color: #777e87;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 126, 135, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #777e87;
  border-color: #777e87;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(119, 126, 135, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #777e87;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 245, 245, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(245, 245, 245, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f5f5f5;
  background-color: transparent;
}

.btn-outline-dark {
  color: #17191b;
  border-color: #17191b;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #17191b;
  border-color: #17191b;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 25, 27, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #17191b;
  border-color: #17191b;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(23, 25, 27, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #17191b;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0062cc;
  text-decoration: underline;
}
.btn-link:focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #9ba0a7;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9375rem 3.625rem;
  font-size: 1.166375rem;
  border-radius: 0.188rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1.5rem;
  font-size: 0.765625rem;
  border-radius: 0.188rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0 0;
  margin: 0;
  font-size: 0.7rem;
  color: #5f656c;
  text-align: left;
  list-style: none;
  background-color: var(--reign-dropdown-bg);
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.188rem;
  box-shadow: var(--reign-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.8rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: 0.188rem;
  border-top-right-radius: 0.188rem;
}
.dropdown-item:last-child {
  border-bottom-right-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #b6babf;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1.2rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #9ba0a7;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.8rem 1.2rem;
  color: rgba(255, 255, 255, 0.5);
}

.dropdown-menu-dark {
  color: #dadcdf;
  background-color: #5f656c;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dadcdf;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #000;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #b6babf;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.5);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dadcdf;
}
.dropdown-menu-dark .dropdown-header {
  color: #b6babf;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.641rem;
  padding-left: 1.641rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 2.71875rem;
  padding-left: 2.71875rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.4rem 1rem;
  color: #007bff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0062cc;
  text-decoration: none;
}
.nav-link.disabled {
  color: #9ba0a7;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 2px solid #dadcdf;
}
.nav-tabs .nav-link {
  margin-bottom: -2px;
  background: none;
  border: 2px solid transparent;
  border-top-left-radius: 0.188rem;
  border-top-right-radius: 0.188rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ecedee #ecedee #dadcdf;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #9ba0a7;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #777e87;
  background-color: #fff;
  border-color: #dadcdf #dadcdf #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.188rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1rem;
  font-size: calc(1.255rem + 0.06vw);
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.3rem;
  }
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.166375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.188rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--reign-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.188rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.188rem - 1px);
  border-top-right-radius: calc(0.188rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.188rem - 1px);
  border-bottom-left-radius: calc(0.188rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.625rem;
}

.card-subtitle {
  margin-top: -0.3125rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.625rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.188rem - 1px) calc(0.188rem - 1px) 0 0;
}

.card-footer {
  padding: 0.625rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.188rem - 1px) calc(0.188rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.625rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.188rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.188rem - 1px);
  border-top-right-radius: calc(0.188rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.188rem - 1px);
  border-bottom-left-radius: calc(0.188rem - 1px);
}

.card-group > .card {
  margin-bottom: 1.25rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: var(--reign-accordion-button-active-color);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--reign-accordion-button-active-color);
  background-color: var(--reign-accordion-button-active-bg);
  box-shadow: inset 0 -1px 0 var(--reign-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3e%3c/svg%3e");
  transform: rotate(90deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0.6rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid var(--reign-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.188rem;
  border-top-right-radius: 0.188rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.188rem - 1px);
  border-top-right-radius: calc(0.188rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.188rem - 1px);
  border-bottom-left-radius: calc(0.188rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}

.accordion-body {
  padding: 1.25rem 3.75rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  list-style: none;
  background-color: "transparent";
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #c8cbcf;
  content: var(--reign-breadcrumb-divider, "»") /* rtl: var(--reign-breadcrumb-divider, "»") */;
}
.breadcrumb-item.active {
  color: #fff;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: var(--reign-pagination-color);
  background-color: #fff;
  border: 1px solid var(--reign-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--reign-pagination-hover-color);
  text-decoration: none;
  background-color: var(--reign-pagination-hover-bg);
  border-color: var(--reign-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: #0062cc;
  background-color: #ecedee;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: var(--reign-pagination-active-color);
  background-color: var(--reign-pagination-active-bg);
  border-color: var(--reign-pagination-active-border-color);
}
.page-item.disabled .page-link {
  color: #c8cbcf;
  pointer-events: none;
  background-color: #fff;
  border-color: #ecedee;
}

.page-link {
  padding: 0.5rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.188rem;
  border-bottom-right-radius: 0.188rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.166375rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.188rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.188rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.5625rem 1.25rem;
}

.alert-primary {
  color: #004a99;
  background-color: #cce5ff;
  border-color: #b3d7ff;
}
.alert-primary .alert-link {
  color: #003b7a;
}

.alert-secondary {
  color: #474c51;
  background-color: #e4e5e7;
  border-color: #d6d8db;
}
.alert-secondary .alert-link {
  color: #393d41;
}

.alert-success {
  color: #186429;
  background-color: #d4edda;
  border-color: #bfe5c7;
}
.alert-success .alert-link {
  color: #135021;
}

.alert-info {
  color: #0e616e;
  background-color: #d1ecf1;
  border-color: #b9e3ea;
}
.alert-info .alert-link {
  color: #0b4e58;
}

.alert-warning {
  color: #997404;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #7a5d03;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #939393;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light .alert-link {
  color: #767676;
}

.alert-dark {
  color: #0e0f10;
  background-color: #d1d1d1;
  border-color: #b9babb;
}
.alert-dark .alert-link {
  color: #0b0c0d;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #ecedee;
  border-radius: 0.188rem;
  box-shadow: var(--reign-box-shadow-inset);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.188rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #777e87;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #777e87;
  text-decoration: none;
  background-color: #f5f5f5;
}
.list-group-item-action:active {
  color: #5f656c;
  background-color: #ecedee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.625rem 1.25rem;
  color: #4f545a;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #9ba0a7;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.188rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.188rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.188rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.188rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.188rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.188rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.188rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.188rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.188rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.188rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004a99;
  background-color: #cce5ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004a99;
  background-color: #b8cee6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004a99;
  border-color: #004a99;
}

.list-group-item-secondary {
  color: #474c51;
  background-color: #e4e5e7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #474c51;
  background-color: #cdced0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #474c51;
  border-color: #474c51;
}

.list-group-item-success {
  color: #186429;
  background-color: #d4edda;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #186429;
  background-color: #bfd5c4;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #186429;
  border-color: #186429;
}

.list-group-item-info {
  color: #0e616e;
  background-color: #d1ecf1;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0e616e;
  background-color: #bcd4d9;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e616e;
  border-color: #0e616e;
}

.list-group-item-warning {
  color: #997404;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997404;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997404;
  border-color: #997404;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #939393;
  background-color: #fdfdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #939393;
  background-color: #e4e4e4;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #939393;
  border-color: #939393;
}

.list-group-item-dark {
  color: #0e0f10;
  background-color: #d1d1d1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0e0f10;
  background-color: #bcbcbc;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0e0f10;
  border-color: #0e0f10;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.188rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: var(--reign-box-shadow);
  border-radius: 0.188rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1.25rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #9ba0a7;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.188rem - 1px);
  border-top-right-radius: calc(0.188rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
  box-shadow: var(--reign-box-shadow-sm);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-bottom: 1px solid var(--reign-border-color);
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
}
.modal-header .btn-close {
  padding: 0.625rem 0.625rem;
  margin: -0.625rem -0.625rem -0.625rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid var(--reign-border-color);
  border-bottom-right-radius: calc(0.625rem - 1px);
  border-bottom-left-radius: calc(0.625rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: var(--reign-box-shadow);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.3125rem 0.625rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.188rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--reign-box-shadow-sm);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
}
.offcanvas-header .btn-close {
  padding: 0.625rem 0.625rem;
  margin-top: -0.625rem;
  margin-right: -0.625rem;
  margin-bottom: -0.625rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.25rem 1.25rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--reign-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --reign-aspect-ratio: 100%;
}

.ratio-4x3 {
  --reign-aspect-ratio: 75%;
}

.ratio-16x9 {
  --reign-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --reign-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1015;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1015;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-85 {
  opacity: 0.85 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--reign-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--reign-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--reign-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid var(--reign-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid var(--reign-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid var(--reign-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid var(--reign-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid var(--reign-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-facebook {
  border-color: var(--reign-facebook) !important;
}

.border-google-plus {
  border-color: var(--reign-google-plus) !important;
}

.border-twitter {
  border-color: var(--reign-twitter) !important;
}

.border-linkedin {
  border-color: var(--reign-linkedin) !important;
}

.border-youtube {
  border-color: var(--reign-youtube) !important;
}

.border-github {
  border-color: var(--reign-github) !important;
}

.border-black {
  border-color: var(--reign-black) !important;
}

.border-100 {
  border-color: var(--reign-100) !important;
}

.border-200 {
  border-color: var(--reign-200) !important;
}

.border-300 {
  border-color: var(--reign-300) !important;
}

.border-400 {
  border-color: var(--reign-400) !important;
}

.border-500 {
  border-color: var(--reign-500) !important;
}

.border-600 {
  border-color: var(--reign-600) !important;
}

.border-700 {
  border-color: var(--reign-700) !important;
}

.border-800 {
  border-color: var(--reign-800) !important;
}

.border-900 {
  border-color: var(--reign-900) !important;
}

.border-1000 {
  border-color: var(--reign-1000) !important;
}

.border-1100 {
  border-color: var(--reign-1100) !important;
}

.border-white {
  border-color: var(--reign-white) !important;
}

.border-primary {
  border-color: var(--reign-primary) !important;
}

.border-secondary {
  border-color: var(--reign-secondary) !important;
}

.border-success {
  border-color: var(--reign-success) !important;
}

.border-info {
  border-color: var(--reign-info) !important;
}

.border-warning {
  border-color: var(--reign-warning) !important;
}

.border-danger {
  border-color: var(--reign-danger) !important;
}

.border-light {
  border-color: var(--reign-light) !important;
}

.border-dark {
  border-color: var(--reign-dark) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 1.25rem !important;
}

.gap-4 {
  gap: 2.5rem !important;
}

.gap-5 {
  gap: 3.75rem !important;
}

.gap-6 {
  gap: 5rem !important;
}

.gap-7 {
  gap: 6.25rem !important;
}

.gap-8 {
  gap: 7.5rem !important;
}

.gap-9 {
  gap: 8.75rem !important;
}

.gap-10 {
  gap: 10rem !important;
}

.gap-11 {
  gap: 11.25rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 1.25rem !important;
}

.m-4 {
  margin: 2.5rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.m-7 {
  margin: 6.25rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.m-9 {
  margin: 8.75rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.m-11 {
  margin: 11.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-4 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-5 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-6 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-7 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-9 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important;
}

.mx-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-11 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-4 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-5 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-7 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-9 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-11 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 1.25rem !important;
}

.mt-4 {
  margin-top: 2.5rem !important;
}

.mt-5 {
  margin-top: 3.75rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mt-7 {
  margin-top: 6.25rem !important;
}

.mt-8 {
  margin-top: 7.5rem !important;
}

.mt-9 {
  margin-top: 8.75rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt-11 {
  margin-top: 11.25rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 1.25rem !important;
}

.me-4 {
  margin-right: 2.5rem !important;
}

.me-5 {
  margin-right: 3.75rem !important;
}

.me-6 {
  margin-right: 5rem !important;
}

.me-7 {
  margin-right: 6.25rem !important;
}

.me-8 {
  margin-right: 7.5rem !important;
}

.me-9 {
  margin-right: 8.75rem !important;
}

.me-10 {
  margin-right: 10rem !important;
}

.me-11 {
  margin-right: 11.25rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 2.5rem !important;
}

.mb-5 {
  margin-bottom: 3.75rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.mb-7 {
  margin-bottom: 6.25rem !important;
}

.mb-8 {
  margin-bottom: 7.5rem !important;
}

.mb-9 {
  margin-bottom: 8.75rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mb-11 {
  margin-bottom: 11.25rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 1.25rem !important;
}

.ms-4 {
  margin-left: 2.5rem !important;
}

.ms-5 {
  margin-left: 3.75rem !important;
}

.ms-6 {
  margin-left: 5rem !important;
}

.ms-7 {
  margin-left: 6.25rem !important;
}

.ms-8 {
  margin-left: 7.5rem !important;
}

.ms-9 {
  margin-left: 8.75rem !important;
}

.ms-10 {
  margin-left: 10rem !important;
}

.ms-11 {
  margin-left: 11.25rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -1.25rem !important;
}

.m-n4 {
  margin: -2.5rem !important;
}

.m-n5 {
  margin: -3.75rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.m-n7 {
  margin: -6.25rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.m-n9 {
  margin: -8.75rem !important;
}

.m-n10 {
  margin: -10rem !important;
}

.m-n11 {
  margin: -11.25rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n4 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n5 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n6 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n7 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.mx-n8 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n9 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important;
}

.mx-n10 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n11 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n4 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n5 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n6 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n7 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.my-n8 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n9 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important;
}

.my-n10 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n11 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -1.25rem !important;
}

.mt-n4 {
  margin-top: -2.5rem !important;
}

.mt-n5 {
  margin-top: -3.75rem !important;
}

.mt-n6 {
  margin-top: -5rem !important;
}

.mt-n7 {
  margin-top: -6.25rem !important;
}

.mt-n8 {
  margin-top: -7.5rem !important;
}

.mt-n9 {
  margin-top: -8.75rem !important;
}

.mt-n10 {
  margin-top: -10rem !important;
}

.mt-n11 {
  margin-top: -11.25rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -1.25rem !important;
}

.me-n4 {
  margin-right: -2.5rem !important;
}

.me-n5 {
  margin-right: -3.75rem !important;
}

.me-n6 {
  margin-right: -5rem !important;
}

.me-n7 {
  margin-right: -6.25rem !important;
}

.me-n8 {
  margin-right: -7.5rem !important;
}

.me-n9 {
  margin-right: -8.75rem !important;
}

.me-n10 {
  margin-right: -10rem !important;
}

.me-n11 {
  margin-right: -11.25rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -1.25rem !important;
}

.mb-n4 {
  margin-bottom: -2.5rem !important;
}

.mb-n5 {
  margin-bottom: -3.75rem !important;
}

.mb-n6 {
  margin-bottom: -5rem !important;
}

.mb-n7 {
  margin-bottom: -6.25rem !important;
}

.mb-n8 {
  margin-bottom: -7.5rem !important;
}

.mb-n9 {
  margin-bottom: -8.75rem !important;
}

.mb-n10 {
  margin-bottom: -10rem !important;
}

.mb-n11 {
  margin-bottom: -11.25rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -1.25rem !important;
}

.ms-n4 {
  margin-left: -2.5rem !important;
}

.ms-n5 {
  margin-left: -3.75rem !important;
}

.ms-n6 {
  margin-left: -5rem !important;
}

.ms-n7 {
  margin-left: -6.25rem !important;
}

.ms-n8 {
  margin-left: -7.5rem !important;
}

.ms-n9 {
  margin-left: -8.75rem !important;
}

.ms-n10 {
  margin-left: -10rem !important;
}

.ms-n11 {
  margin-left: -11.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 1.25rem !important;
}

.p-4 {
  padding: 2.5rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.p-7 {
  padding: 6.25rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.p-9 {
  padding: 8.75rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.p-11 {
  padding: 11.25rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-4 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-5 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-6 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-7 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-9 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important;
}

.px-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-11 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-5 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-7 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-9 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-11 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 2.5rem !important;
}

.pt-5 {
  padding-top: 3.75rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pt-7 {
  padding-top: 6.25rem !important;
}

.pt-8 {
  padding-top: 7.5rem !important;
}

.pt-9 {
  padding-top: 8.75rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pt-11 {
  padding-top: 11.25rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 1.25rem !important;
}

.pe-4 {
  padding-right: 2.5rem !important;
}

.pe-5 {
  padding-right: 3.75rem !important;
}

.pe-6 {
  padding-right: 5rem !important;
}

.pe-7 {
  padding-right: 6.25rem !important;
}

.pe-8 {
  padding-right: 7.5rem !important;
}

.pe-9 {
  padding-right: 8.75rem !important;
}

.pe-10 {
  padding-right: 10rem !important;
}

.pe-11 {
  padding-right: 11.25rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 2.5rem !important;
}

.pb-5 {
  padding-bottom: 3.75rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.pb-7 {
  padding-bottom: 6.25rem !important;
}

.pb-8 {
  padding-bottom: 7.5rem !important;
}

.pb-9 {
  padding-bottom: 8.75rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.pb-11 {
  padding-bottom: 11.25rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 1.25rem !important;
}

.ps-4 {
  padding-left: 2.5rem !important;
}

.ps-5 {
  padding-left: 3.75rem !important;
}

.ps-6 {
  padding-left: 5rem !important;
}

.ps-7 {
  padding-left: 6.25rem !important;
}

.ps-8 {
  padding-left: 7.5rem !important;
}

.ps-9 {
  padding-left: 8.75rem !important;
}

.ps-10 {
  padding-left: 10rem !important;
}

.ps-11 {
  padding-left: 11.25rem !important;
}

.font-monospace {
  font-family: var(--reign-font-monospace) !important;
}

.fs--2 {
  font-size: 0.6076388889rem !important;
}

.fs--1 {
  font-size: 0.7291666667rem !important;
}

.fs-0 {
  font-size: 0.875rem !important;
}

.fs-1 {
  font-size: 1.2rem !important;
}

.fs-2 {
  font-size: 1.44rem !important;
}

.fs-3 {
  font-size: 1.728rem !important;
}

.fs-4 {
  font-size: 2.0736rem !important;
}

.fs-5 {
  font-size: 2.48832rem !important;
}

.fs-6 {
  font-size: 2.985984rem !important;
}

.fs-7 {
  font-size: 3.5831808rem !important;
}

.fs-8 {
  font-size: 4.29981696rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-thin {
  font-weight: 100 !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1.5rem !important;
}

.lh-xs {
  line-height: 1.15 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --reign-text-opacity: 1;
  color: var(--reign-primary) !important;
}

.text-secondary {
  --reign-text-opacity: 1;
  color: var(--reign-secondary) !important;
}

.text-success {
  --reign-text-opacity: 1;
  color: var(--reign-success) !important;
}

.text-info {
  --reign-text-opacity: 1;
  color: var(--reign-info) !important;
}

.text-warning {
  --reign-text-opacity: 1;
  color: var(--reign-warning) !important;
}

.text-danger {
  --reign-text-opacity: 1;
  color: var(--reign-danger) !important;
}

.text-light {
  --reign-text-opacity: 1;
  color: var(--reign-light) !important;
}

.text-dark {
  --reign-text-opacity: 1;
  color: var(--reign-dark) !important;
}

.text-black {
  --reign-text-opacity: 1;
  color: var(--reign-black) !important;
}

.text-white {
  --reign-text-opacity: 1;
  color: var(--reign-white) !important;
}

.text-body {
  --reign-text-opacity: 1;
  color: var(--reign-body) !important;
}

.text-muted {
  --reign-text-opacity: 1;
  color: var(--reign-muted) !important;
}

.text-black-50 {
  --reign-text-opacity: 1;
  color: var(--reign-black-50) !important;
}

.text-white-50 {
  --reign-text-opacity: 1;
  color: var(--reign-white-50) !important;
}

.text-reset {
  --reign-text-opacity: 1;
  color: var(--reign-reset) !important;
}

.text-opacity-25 {
  --reign-text-opacity: 0.25;
}

.text-opacity-50 {
  --reign-text-opacity: 0.5;
}

.text-opacity-75 {
  --reign-text-opacity: 0.75;
}

.text-opacity-100 {
  --reign-text-opacity: 1;
}

.bg-facebook {
  background-color: var(--reign-facebook) !important;
}

.bg-google-plus {
  background-color: var(--reign-google-plus) !important;
}

.bg-twitter {
  background-color: var(--reign-twitter) !important;
}

.bg-linkedin {
  background-color: var(--reign-linkedin) !important;
}

.bg-youtube {
  background-color: var(--reign-youtube) !important;
}

.bg-github {
  background-color: var(--reign-github) !important;
}

.bg-black {
  background-color: var(--reign-black) !important;
}

.bg-100 {
  background-color: var(--reign-100) !important;
}

.bg-200 {
  background-color: var(--reign-200) !important;
}

.bg-300 {
  background-color: var(--reign-300) !important;
}

.bg-400 {
  background-color: var(--reign-400) !important;
}

.bg-500 {
  background-color: var(--reign-500) !important;
}

.bg-600 {
  background-color: var(--reign-600) !important;
}

.bg-700 {
  background-color: var(--reign-700) !important;
}

.bg-800 {
  background-color: var(--reign-800) !important;
}

.bg-900 {
  background-color: var(--reign-900) !important;
}

.bg-1000 {
  background-color: var(--reign-1000) !important;
}

.bg-1100 {
  background-color: var(--reign-1100) !important;
}

.bg-white {
  background-color: var(--reign-white) !important;
}

.bg-primary {
  background-color: var(--reign-primary) !important;
}

.bg-secondary {
  background-color: var(--reign-secondary) !important;
}

.bg-success {
  background-color: var(--reign-success) !important;
}

.bg-info {
  background-color: var(--reign-info) !important;
}

.bg-warning {
  background-color: var(--reign-warning) !important;
}

.bg-danger {
  background-color: var(--reign-danger) !important;
}

.bg-light {
  background-color: var(--reign-light) !important;
}

.bg-dark {
  background-color: var(--reign-dark) !important;
}

.bg-body {
  background-color: var(--reign-body) !important;
}

.bg-transparent {
  background-color: var(--reign-transparent) !important;
}

.bg-opacity-10 {
  --reign-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --reign-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --reign-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --reign-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --reign-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--reign-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.188rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.188rem !important;
}

.rounded-3 {
  border-radius: 0.625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.188rem !important;
  border-top-right-radius: 0.188rem !important;
}

.rounded-top-lg {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0.188rem !important;
  border-bottom-right-radius: 0.188rem !important;
}

.rounded-end-lg {
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.188rem !important;
  border-bottom-left-radius: 0.188rem !important;
}

.rounded-bottom-lg {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0.188rem !important;
  border-top-left-radius: 0.188rem !important;
}

.rounded-start-lg {
  border-bottom-left-radius: 0.625rem !important;
  border-top-left-radius: 0.625rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.text-facebook {
  color: var(--reign-facebook) !important;
}

.text-google-plus {
  color: var(--reign-google-plus) !important;
}

.text-twitter {
  color: var(--reign-twitter) !important;
}

.text-linkedin {
  color: var(--reign-linkedin) !important;
}

.text-youtube {
  color: var(--reign-youtube) !important;
}

.text-github {
  color: var(--reign-github) !important;
}

.text-black {
  color: var(--reign-black) !important;
}

.text-100 {
  color: var(--reign-100) !important;
}

.text-200 {
  color: var(--reign-200) !important;
}

.text-300 {
  color: var(--reign-300) !important;
}

.text-400 {
  color: var(--reign-400) !important;
}

.text-500 {
  color: var(--reign-500) !important;
}

.text-600 {
  color: var(--reign-600) !important;
}

.text-700 {
  color: var(--reign-700) !important;
}

.text-800 {
  color: var(--reign-800) !important;
}

.text-900 {
  color: var(--reign-900) !important;
}

.text-1000 {
  color: var(--reign-1000) !important;
}

.text-1100 {
  color: var(--reign-1100) !important;
}

.text-white {
  color: var(--reign-white) !important;
}

.ls-1 {
  letter-spacing: 0.05em !important;
}

.ls-2 {
  letter-spacing: 0.14em !important;
}

.ls-3 {
  letter-spacing: 0.2em !important;
}

.ls-4 {
  letter-spacing: 0.25em !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.border-x {
  border-left: 1px solid var(--reign-border-color) !important;
  border-right: 1px solid var(--reign-border-color) !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-y {
  border-top: 1px solid var(--reign-border-color) !important;
  border-bottom: 1px solid var(--reign-border-color) !important;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.border-dashed {
  border: 1px dashed var(--reign-border-color) !important;
}

.border-dashed-top {
  border-top: 1px dashed var(--reign-border-color) !important;
}

.border-dashed-end {
  border-right: 1px dashed #9ba0a7 !important;
}

.border-dashed-start {
  border-left: 1px dashed #9ba0a7 !important;
}

.border-dashed-bottom {
  border-bottom: 1px dashed var(--reign-border-color) !important;
}

.border-dashed-x {
  border-left: 1px dashed var(--reign-border-color) !important;
  border-right: 1px dashed var(--reign-border-color) !important;
}

.border-dashed-y {
  border-top: 1px dashed var(--reign-border-color) !important;
  border-bottom: 1px dashed var(--reign-border-color) !important;
}

.rounded-ts {
  border-top-left-radius: 0.188rem !important;
}

.rounded-ts-lg {
  border-top-left-radius: 0.625rem !important;
}

.rounded-ts-0 {
  border-top-left-radius: 0 !important;
}

.rounded-te {
  border-top-right-radius: 0.188rem !important;
}

.rounded-te-lg {
  border-top-right-radius: 0.625rem !important;
}

.rounded-te-0 {
  border-top-right-radius: 0 !important;
}

.rounded-bs {
  border-bottom-left-radius: 0.188rem !important;
}

.rounded-bs-lg {
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-bs-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-be {
  border-bottom-right-radius: 0.188rem !important;
}

.rounded-be-lg {
  border-bottom-right-radius: 0.625rem !important;
}

.rounded-be-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .opacity-sm-0 {
    opacity: 0 !important;
  }

  .opacity-sm-25 {
    opacity: 0.25 !important;
  }

  .opacity-sm-50 {
    opacity: 0.5 !important;
  }

  .opacity-sm-75 {
    opacity: 0.75 !important;
  }

  .opacity-sm-85 {
    opacity: 0.85 !important;
  }

  .opacity-sm-100 {
    opacity: 1 !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }

  .translate-sm-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }

  .translate-sm-middle-x {
    transform: translateX(-50%) !important;
  }

  .translate-sm-middle-y {
    transform: translateY(-50%) !important;
  }

  .border-sm {
    border: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-sm-top {
    border-top: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-top-0 {
    border-top: 0 !important;
  }

  .border-sm-end {
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-end-0 {
    border-right: 0 !important;
  }

  .border-sm-bottom {
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-sm-start {
    border-left: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-start-0 {
    border-left: 0 !important;
  }

  .border-sm-facebook {
    border-color: var(--reign-facebook) !important;
  }

  .border-sm-google-plus {
    border-color: var(--reign-google-plus) !important;
  }

  .border-sm-twitter {
    border-color: var(--reign-twitter) !important;
  }

  .border-sm-linkedin {
    border-color: var(--reign-linkedin) !important;
  }

  .border-sm-youtube {
    border-color: var(--reign-youtube) !important;
  }

  .border-sm-github {
    border-color: var(--reign-github) !important;
  }

  .border-sm-black {
    border-color: var(--reign-black) !important;
  }

  .border-sm-100 {
    border-color: var(--reign-100) !important;
  }

  .border-sm-200 {
    border-color: var(--reign-200) !important;
  }

  .border-sm-300 {
    border-color: var(--reign-300) !important;
  }

  .border-sm-400 {
    border-color: var(--reign-400) !important;
  }

  .border-sm-500 {
    border-color: var(--reign-500) !important;
  }

  .border-sm-600 {
    border-color: var(--reign-600) !important;
  }

  .border-sm-700 {
    border-color: var(--reign-700) !important;
  }

  .border-sm-800 {
    border-color: var(--reign-800) !important;
  }

  .border-sm-900 {
    border-color: var(--reign-900) !important;
  }

  .border-sm-1000 {
    border-color: var(--reign-1000) !important;
  }

  .border-sm-1100 {
    border-color: var(--reign-1100) !important;
  }

  .border-sm-white {
    border-color: var(--reign-white) !important;
  }

  .border-sm-primary {
    border-color: var(--reign-primary) !important;
  }

  .border-sm-secondary {
    border-color: var(--reign-secondary) !important;
  }

  .border-sm-success {
    border-color: var(--reign-success) !important;
  }

  .border-sm-info {
    border-color: var(--reign-info) !important;
  }

  .border-sm-warning {
    border-color: var(--reign-warning) !important;
  }

  .border-sm-danger {
    border-color: var(--reign-danger) !important;
  }

  .border-sm-light {
    border-color: var(--reign-light) !important;
  }

  .border-sm-dark {
    border-color: var(--reign-dark) !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .vw-sm-25 {
    width: 25vw !important;
  }

  .vw-sm-50 {
    width: 50vw !important;
  }

  .vw-sm-75 {
    width: 75vw !important;
  }

  .vw-sm-100 {
    width: 100vw !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .vh-sm-25 {
    height: 25vh !important;
  }

  .vh-sm-50 {
    height: 50vh !important;
  }

  .vh-sm-75 {
    height: 75vh !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .min-vh-sm-25 {
    min-height: 25vh !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .min-vh-sm-100 {
    min-height: 100vh !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.3125rem !important;
  }

  .gap-sm-2 {
    gap: 0.625rem !important;
  }

  .gap-sm-3 {
    gap: 1.25rem !important;
  }

  .gap-sm-4 {
    gap: 2.5rem !important;
  }

  .gap-sm-5 {
    gap: 3.75rem !important;
  }

  .gap-sm-6 {
    gap: 5rem !important;
  }

  .gap-sm-7 {
    gap: 6.25rem !important;
  }

  .gap-sm-8 {
    gap: 7.5rem !important;
  }

  .gap-sm-9 {
    gap: 8.75rem !important;
  }

  .gap-sm-10 {
    gap: 10rem !important;
  }

  .gap-sm-11 {
    gap: 11.25rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.3125rem !important;
  }

  .m-sm-2 {
    margin: 0.625rem !important;
  }

  .m-sm-3 {
    margin: 1.25rem !important;
  }

  .m-sm-4 {
    margin: 2.5rem !important;
  }

  .m-sm-5 {
    margin: 3.75rem !important;
  }

  .m-sm-6 {
    margin: 5rem !important;
  }

  .m-sm-7 {
    margin: 6.25rem !important;
  }

  .m-sm-8 {
    margin: 7.5rem !important;
  }

  .m-sm-9 {
    margin: 8.75rem !important;
  }

  .m-sm-10 {
    margin: 10rem !important;
  }

  .m-sm-11 {
    margin: 11.25rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .mx-sm-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-7 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }

  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-11 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }

  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .my-sm-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-7 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-sm-9 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }

  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-11 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-6 {
    margin-top: 5rem !important;
  }

  .mt-sm-7 {
    margin-top: 6.25rem !important;
  }

  .mt-sm-8 {
    margin-top: 7.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 8.75rem !important;
  }

  .mt-sm-10 {
    margin-top: 10rem !important;
  }

  .mt-sm-11 {
    margin-top: 11.25rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }

  .me-sm-2 {
    margin-right: 0.625rem !important;
  }

  .me-sm-3 {
    margin-right: 1.25rem !important;
  }

  .me-sm-4 {
    margin-right: 2.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3.75rem !important;
  }

  .me-sm-6 {
    margin-right: 5rem !important;
  }

  .me-sm-7 {
    margin-right: 6.25rem !important;
  }

  .me-sm-8 {
    margin-right: 7.5rem !important;
  }

  .me-sm-9 {
    margin-right: 8.75rem !important;
  }

  .me-sm-10 {
    margin-right: 10rem !important;
  }

  .me-sm-11 {
    margin-right: 11.25rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6.25rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 7.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 8.75rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 11.25rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }

  .ms-sm-3 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-4 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-6 {
    margin-left: 5rem !important;
  }

  .ms-sm-7 {
    margin-left: 6.25rem !important;
  }

  .ms-sm-8 {
    margin-left: 7.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 8.75rem !important;
  }

  .ms-sm-10 {
    margin-left: 10rem !important;
  }

  .ms-sm-11 {
    margin-left: 11.25rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.3125rem !important;
  }

  .m-sm-n2 {
    margin: -0.625rem !important;
  }

  .m-sm-n3 {
    margin: -1.25rem !important;
  }

  .m-sm-n4 {
    margin: -2.5rem !important;
  }

  .m-sm-n5 {
    margin: -3.75rem !important;
  }

  .m-sm-n6 {
    margin: -5rem !important;
  }

  .m-sm-n7 {
    margin: -6.25rem !important;
  }

  .m-sm-n8 {
    margin: -7.5rem !important;
  }

  .m-sm-n9 {
    margin: -8.75rem !important;
  }

  .m-sm-n10 {
    margin: -10rem !important;
  }

  .m-sm-n11 {
    margin: -11.25rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n4 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .mx-sm-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }

  .mx-sm-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n11 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .my-sm-n3 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n4 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n7 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .my-sm-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }

  .my-sm-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n11 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n4 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n6 {
    margin-top: -5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -6.25rem !important;
  }

  .mt-sm-n8 {
    margin-top: -7.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -8.75rem !important;
  }

  .mt-sm-n10 {
    margin-top: -10rem !important;
  }

  .mt-sm-n11 {
    margin-top: -11.25rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }

  .me-sm-n3 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n4 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n6 {
    margin-right: -5rem !important;
  }

  .me-sm-n7 {
    margin-right: -6.25rem !important;
  }

  .me-sm-n8 {
    margin-right: -7.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -8.75rem !important;
  }

  .me-sm-n10 {
    margin-right: -10rem !important;
  }

  .me-sm-n11 {
    margin-right: -11.25rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -6.25rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -7.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -8.75rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -11.25rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n4 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n6 {
    margin-left: -5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -6.25rem !important;
  }

  .ms-sm-n8 {
    margin-left: -7.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -8.75rem !important;
  }

  .ms-sm-n10 {
    margin-left: -10rem !important;
  }

  .ms-sm-n11 {
    margin-left: -11.25rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.3125rem !important;
  }

  .p-sm-2 {
    padding: 0.625rem !important;
  }

  .p-sm-3 {
    padding: 1.25rem !important;
  }

  .p-sm-4 {
    padding: 2.5rem !important;
  }

  .p-sm-5 {
    padding: 3.75rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .p-sm-7 {
    padding: 6.25rem !important;
  }

  .p-sm-8 {
    padding: 7.5rem !important;
  }

  .p-sm-9 {
    padding: 8.75rem !important;
  }

  .p-sm-10 {
    padding: 10rem !important;
  }

  .p-sm-11 {
    padding: 11.25rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }

  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .px-sm-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-7 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-sm-9 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }

  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-11 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }

  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .py-sm-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-7 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-sm-9 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }

  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-11 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-6 {
    padding-top: 5rem !important;
  }

  .pt-sm-7 {
    padding-top: 6.25rem !important;
  }

  .pt-sm-8 {
    padding-top: 7.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 8.75rem !important;
  }

  .pt-sm-10 {
    padding-top: 10rem !important;
  }

  .pt-sm-11 {
    padding-top: 11.25rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }

  .pe-sm-3 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-4 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-6 {
    padding-right: 5rem !important;
  }

  .pe-sm-7 {
    padding-right: 6.25rem !important;
  }

  .pe-sm-8 {
    padding-right: 7.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 8.75rem !important;
  }

  .pe-sm-10 {
    padding-right: 10rem !important;
  }

  .pe-sm-11 {
    padding-right: 11.25rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6.25rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 7.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 8.75rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 11.25rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }

  .ps-sm-3 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-4 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-6 {
    padding-left: 5rem !important;
  }

  .ps-sm-7 {
    padding-left: 6.25rem !important;
  }

  .ps-sm-8 {
    padding-left: 7.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 8.75rem !important;
  }

  .ps-sm-10 {
    padding-left: 10rem !important;
  }

  .ps-sm-11 {
    padding-left: 11.25rem !important;
  }

  .fs-sm--2 {
    font-size: 0.6076388889rem !important;
  }

  .fs-sm--1 {
    font-size: 0.7291666667rem !important;
  }

  .fs-sm-0 {
    font-size: 0.875rem !important;
  }

  .fs-sm-1 {
    font-size: 1.2rem !important;
  }

  .fs-sm-2 {
    font-size: 1.44rem !important;
  }

  .fs-sm-3 {
    font-size: 1.728rem !important;
  }

  .fs-sm-4 {
    font-size: 2.0736rem !important;
  }

  .fs-sm-5 {
    font-size: 2.48832rem !important;
  }

  .fs-sm-6 {
    font-size: 2.985984rem !important;
  }

  .fs-sm-7 {
    font-size: 3.5831808rem !important;
  }

  .fs-sm-8 {
    font-size: 4.29981696rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .rounded-sm-top {
    border-top-left-radius: 0.188rem !important;
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-sm-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-sm-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-sm-end {
    border-top-right-radius: 0.188rem !important;
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-sm-end-lg {
    border-top-right-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-sm-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-sm-bottom {
    border-bottom-right-radius: 0.188rem !important;
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-sm-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-sm-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-sm-start {
    border-bottom-left-radius: 0.188rem !important;
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-sm-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-sm-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .max-vh-sm-25 {
    max-height: 25vh !important;
  }

  .max-vh-sm-50 {
    max-height: 50vh !important;
  }

  .max-vh-sm-75 {
    max-height: 75vh !important;
  }

  .max-vh-sm-100 {
    max-height: 100vh !important;
  }

  .border-sm-x {
    border-left: 1px solid var(--reign-border-color) !important;
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .border-sm-y {
    border-top: 1px solid var(--reign-border-color) !important;
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-sm-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  .border-sm-dashed {
    border: 1px dashed var(--reign-border-color) !important;
  }

  .border-sm-dashed-top {
    border-top: 1px dashed var(--reign-border-color) !important;
  }

  .border-sm-dashed-end {
    border-right: 1px dashed #9ba0a7 !important;
  }

  .border-sm-dashed-start {
    border-left: 1px dashed #9ba0a7 !important;
  }

  .border-sm-dashed-bottom {
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .border-sm-dashed-x {
    border-left: 1px dashed var(--reign-border-color) !important;
    border-right: 1px dashed var(--reign-border-color) !important;
  }

  .border-sm-dashed-y {
    border-top: 1px dashed var(--reign-border-color) !important;
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .rounded-sm-ts {
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-sm-ts-lg {
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-sm-ts-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-sm-te {
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-sm-te-lg {
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-sm-te-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-sm-bs {
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-sm-bs-lg {
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-sm-bs-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-sm-be {
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-sm-be-lg {
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-sm-be-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .opacity-md-0 {
    opacity: 0 !important;
  }

  .opacity-md-25 {
    opacity: 0.25 !important;
  }

  .opacity-md-50 {
    opacity: 0.5 !important;
  }

  .opacity-md-75 {
    opacity: 0.75 !important;
  }

  .opacity-md-85 {
    opacity: 0.85 !important;
  }

  .opacity-md-100 {
    opacity: 1 !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }

  .translate-md-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }

  .translate-md-middle-x {
    transform: translateX(-50%) !important;
  }

  .translate-md-middle-y {
    transform: translateY(-50%) !important;
  }

  .border-md {
    border: 1px solid var(--reign-border-color) !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-md-top {
    border-top: 1px solid var(--reign-border-color) !important;
  }

  .border-md-top-0 {
    border-top: 0 !important;
  }

  .border-md-end {
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-md-end-0 {
    border-right: 0 !important;
  }

  .border-md-bottom {
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-md-start {
    border-left: 1px solid var(--reign-border-color) !important;
  }

  .border-md-start-0 {
    border-left: 0 !important;
  }

  .border-md-facebook {
    border-color: var(--reign-facebook) !important;
  }

  .border-md-google-plus {
    border-color: var(--reign-google-plus) !important;
  }

  .border-md-twitter {
    border-color: var(--reign-twitter) !important;
  }

  .border-md-linkedin {
    border-color: var(--reign-linkedin) !important;
  }

  .border-md-youtube {
    border-color: var(--reign-youtube) !important;
  }

  .border-md-github {
    border-color: var(--reign-github) !important;
  }

  .border-md-black {
    border-color: var(--reign-black) !important;
  }

  .border-md-100 {
    border-color: var(--reign-100) !important;
  }

  .border-md-200 {
    border-color: var(--reign-200) !important;
  }

  .border-md-300 {
    border-color: var(--reign-300) !important;
  }

  .border-md-400 {
    border-color: var(--reign-400) !important;
  }

  .border-md-500 {
    border-color: var(--reign-500) !important;
  }

  .border-md-600 {
    border-color: var(--reign-600) !important;
  }

  .border-md-700 {
    border-color: var(--reign-700) !important;
  }

  .border-md-800 {
    border-color: var(--reign-800) !important;
  }

  .border-md-900 {
    border-color: var(--reign-900) !important;
  }

  .border-md-1000 {
    border-color: var(--reign-1000) !important;
  }

  .border-md-1100 {
    border-color: var(--reign-1100) !important;
  }

  .border-md-white {
    border-color: var(--reign-white) !important;
  }

  .border-md-primary {
    border-color: var(--reign-primary) !important;
  }

  .border-md-secondary {
    border-color: var(--reign-secondary) !important;
  }

  .border-md-success {
    border-color: var(--reign-success) !important;
  }

  .border-md-info {
    border-color: var(--reign-info) !important;
  }

  .border-md-warning {
    border-color: var(--reign-warning) !important;
  }

  .border-md-danger {
    border-color: var(--reign-danger) !important;
  }

  .border-md-light {
    border-color: var(--reign-light) !important;
  }

  .border-md-dark {
    border-color: var(--reign-dark) !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .vw-md-25 {
    width: 25vw !important;
  }

  .vw-md-50 {
    width: 50vw !important;
  }

  .vw-md-75 {
    width: 75vw !important;
  }

  .vw-md-100 {
    width: 100vw !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .vh-md-25 {
    height: 25vh !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .vh-md-75 {
    height: 75vh !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .min-vh-md-25 {
    min-height: 25vh !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .min-vh-md-100 {
    min-height: 100vh !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.3125rem !important;
  }

  .gap-md-2 {
    gap: 0.625rem !important;
  }

  .gap-md-3 {
    gap: 1.25rem !important;
  }

  .gap-md-4 {
    gap: 2.5rem !important;
  }

  .gap-md-5 {
    gap: 3.75rem !important;
  }

  .gap-md-6 {
    gap: 5rem !important;
  }

  .gap-md-7 {
    gap: 6.25rem !important;
  }

  .gap-md-8 {
    gap: 7.5rem !important;
  }

  .gap-md-9 {
    gap: 8.75rem !important;
  }

  .gap-md-10 {
    gap: 10rem !important;
  }

  .gap-md-11 {
    gap: 11.25rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.3125rem !important;
  }

  .m-md-2 {
    margin: 0.625rem !important;
  }

  .m-md-3 {
    margin: 1.25rem !important;
  }

  .m-md-4 {
    margin: 2.5rem !important;
  }

  .m-md-5 {
    margin: 3.75rem !important;
  }

  .m-md-6 {
    margin: 5rem !important;
  }

  .m-md-7 {
    margin: 6.25rem !important;
  }

  .m-md-8 {
    margin: 7.5rem !important;
  }

  .m-md-9 {
    margin: 8.75rem !important;
  }

  .m-md-10 {
    margin: 10rem !important;
  }

  .m-md-11 {
    margin: 11.25rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }

  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .mx-md-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-7 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-md-9 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }

  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-11 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }

  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .my-md-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-7 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-md-9 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }

  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-11 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }

  .mt-md-2 {
    margin-top: 0.625rem !important;
  }

  .mt-md-3 {
    margin-top: 1.25rem !important;
  }

  .mt-md-4 {
    margin-top: 2.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3.75rem !important;
  }

  .mt-md-6 {
    margin-top: 5rem !important;
  }

  .mt-md-7 {
    margin-top: 6.25rem !important;
  }

  .mt-md-8 {
    margin-top: 7.5rem !important;
  }

  .mt-md-9 {
    margin-top: 8.75rem !important;
  }

  .mt-md-10 {
    margin-top: 10rem !important;
  }

  .mt-md-11 {
    margin-top: 11.25rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.3125rem !important;
  }

  .me-md-2 {
    margin-right: 0.625rem !important;
  }

  .me-md-3 {
    margin-right: 1.25rem !important;
  }

  .me-md-4 {
    margin-right: 2.5rem !important;
  }

  .me-md-5 {
    margin-right: 3.75rem !important;
  }

  .me-md-6 {
    margin-right: 5rem !important;
  }

  .me-md-7 {
    margin-right: 6.25rem !important;
  }

  .me-md-8 {
    margin-right: 7.5rem !important;
  }

  .me-md-9 {
    margin-right: 8.75rem !important;
  }

  .me-md-10 {
    margin-right: 10rem !important;
  }

  .me-md-11 {
    margin-right: 11.25rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-6 {
    margin-bottom: 5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6.25rem !important;
  }

  .mb-md-8 {
    margin-bottom: 7.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 8.75rem !important;
  }

  .mb-md-10 {
    margin-bottom: 10rem !important;
  }

  .mb-md-11 {
    margin-bottom: 11.25rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }

  .ms-md-2 {
    margin-left: 0.625rem !important;
  }

  .ms-md-3 {
    margin-left: 1.25rem !important;
  }

  .ms-md-4 {
    margin-left: 2.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3.75rem !important;
  }

  .ms-md-6 {
    margin-left: 5rem !important;
  }

  .ms-md-7 {
    margin-left: 6.25rem !important;
  }

  .ms-md-8 {
    margin-left: 7.5rem !important;
  }

  .ms-md-9 {
    margin-left: 8.75rem !important;
  }

  .ms-md-10 {
    margin-left: 10rem !important;
  }

  .ms-md-11 {
    margin-left: 11.25rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.3125rem !important;
  }

  .m-md-n2 {
    margin: -0.625rem !important;
  }

  .m-md-n3 {
    margin: -1.25rem !important;
  }

  .m-md-n4 {
    margin: -2.5rem !important;
  }

  .m-md-n5 {
    margin: -3.75rem !important;
  }

  .m-md-n6 {
    margin: -5rem !important;
  }

  .m-md-n7 {
    margin: -6.25rem !important;
  }

  .m-md-n8 {
    margin: -7.5rem !important;
  }

  .m-md-n9 {
    margin: -8.75rem !important;
  }

  .m-md-n10 {
    margin: -10rem !important;
  }

  .m-md-n11 {
    margin: -11.25rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .mx-md-n3 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n4 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n7 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .mx-md-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }

  .mx-md-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n11 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }

  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }

  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .my-md-n3 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n4 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n7 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .my-md-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .my-md-n9 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }

  .my-md-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n11 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }

  .mt-md-n3 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n4 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n6 {
    margin-top: -5rem !important;
  }

  .mt-md-n7 {
    margin-top: -6.25rem !important;
  }

  .mt-md-n8 {
    margin-top: -7.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -8.75rem !important;
  }

  .mt-md-n10 {
    margin-top: -10rem !important;
  }

  .mt-md-n11 {
    margin-top: -11.25rem !important;
  }

  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }

  .me-md-n2 {
    margin-right: -0.625rem !important;
  }

  .me-md-n3 {
    margin-right: -1.25rem !important;
  }

  .me-md-n4 {
    margin-right: -2.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3.75rem !important;
  }

  .me-md-n6 {
    margin-right: -5rem !important;
  }

  .me-md-n7 {
    margin-right: -6.25rem !important;
  }

  .me-md-n8 {
    margin-right: -7.5rem !important;
  }

  .me-md-n9 {
    margin-right: -8.75rem !important;
  }

  .me-md-n10 {
    margin-right: -10rem !important;
  }

  .me-md-n11 {
    margin-right: -11.25rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -6.25rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -7.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -8.75rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -11.25rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }

  .ms-md-n3 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n4 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n6 {
    margin-left: -5rem !important;
  }

  .ms-md-n7 {
    margin-left: -6.25rem !important;
  }

  .ms-md-n8 {
    margin-left: -7.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -8.75rem !important;
  }

  .ms-md-n10 {
    margin-left: -10rem !important;
  }

  .ms-md-n11 {
    margin-left: -11.25rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.3125rem !important;
  }

  .p-md-2 {
    padding: 0.625rem !important;
  }

  .p-md-3 {
    padding: 1.25rem !important;
  }

  .p-md-4 {
    padding: 2.5rem !important;
  }

  .p-md-5 {
    padding: 3.75rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .p-md-7 {
    padding: 6.25rem !important;
  }

  .p-md-8 {
    padding: 7.5rem !important;
  }

  .p-md-9 {
    padding: 8.75rem !important;
  }

  .p-md-10 {
    padding: 10rem !important;
  }

  .p-md-11 {
    padding: 11.25rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }

  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .px-md-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-7 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-md-9 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }

  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-11 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }

  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .py-md-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-7 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-md-9 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }

  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-11 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }

  .pt-md-2 {
    padding-top: 0.625rem !important;
  }

  .pt-md-3 {
    padding-top: 1.25rem !important;
  }

  .pt-md-4 {
    padding-top: 2.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3.75rem !important;
  }

  .pt-md-6 {
    padding-top: 5rem !important;
  }

  .pt-md-7 {
    padding-top: 6.25rem !important;
  }

  .pt-md-8 {
    padding-top: 7.5rem !important;
  }

  .pt-md-9 {
    padding-top: 8.75rem !important;
  }

  .pt-md-10 {
    padding-top: 10rem !important;
  }

  .pt-md-11 {
    padding-top: 11.25rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }

  .pe-md-2 {
    padding-right: 0.625rem !important;
  }

  .pe-md-3 {
    padding-right: 1.25rem !important;
  }

  .pe-md-4 {
    padding-right: 2.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3.75rem !important;
  }

  .pe-md-6 {
    padding-right: 5rem !important;
  }

  .pe-md-7 {
    padding-right: 6.25rem !important;
  }

  .pe-md-8 {
    padding-right: 7.5rem !important;
  }

  .pe-md-9 {
    padding-right: 8.75rem !important;
  }

  .pe-md-10 {
    padding-right: 10rem !important;
  }

  .pe-md-11 {
    padding-right: 11.25rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-6 {
    padding-bottom: 5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6.25rem !important;
  }

  .pb-md-8 {
    padding-bottom: 7.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 8.75rem !important;
  }

  .pb-md-10 {
    padding-bottom: 10rem !important;
  }

  .pb-md-11 {
    padding-bottom: 11.25rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }

  .ps-md-2 {
    padding-left: 0.625rem !important;
  }

  .ps-md-3 {
    padding-left: 1.25rem !important;
  }

  .ps-md-4 {
    padding-left: 2.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3.75rem !important;
  }

  .ps-md-6 {
    padding-left: 5rem !important;
  }

  .ps-md-7 {
    padding-left: 6.25rem !important;
  }

  .ps-md-8 {
    padding-left: 7.5rem !important;
  }

  .ps-md-9 {
    padding-left: 8.75rem !important;
  }

  .ps-md-10 {
    padding-left: 10rem !important;
  }

  .ps-md-11 {
    padding-left: 11.25rem !important;
  }

  .fs-md--2 {
    font-size: 0.6076388889rem !important;
  }

  .fs-md--1 {
    font-size: 0.7291666667rem !important;
  }

  .fs-md-0 {
    font-size: 0.875rem !important;
  }

  .fs-md-1 {
    font-size: 1.2rem !important;
  }

  .fs-md-2 {
    font-size: 1.44rem !important;
  }

  .fs-md-3 {
    font-size: 1.728rem !important;
  }

  .fs-md-4 {
    font-size: 2.0736rem !important;
  }

  .fs-md-5 {
    font-size: 2.48832rem !important;
  }

  .fs-md-6 {
    font-size: 2.985984rem !important;
  }

  .fs-md-7 {
    font-size: 3.5831808rem !important;
  }

  .fs-md-8 {
    font-size: 4.29981696rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .rounded-md-top {
    border-top-left-radius: 0.188rem !important;
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-md-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-md-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-md-end {
    border-top-right-radius: 0.188rem !important;
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-md-end-lg {
    border-top-right-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-md-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-md-bottom {
    border-bottom-right-radius: 0.188rem !important;
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-md-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-md-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-md-start {
    border-bottom-left-radius: 0.188rem !important;
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-md-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-md-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .max-vh-md-25 {
    max-height: 25vh !important;
  }

  .max-vh-md-50 {
    max-height: 50vh !important;
  }

  .max-vh-md-75 {
    max-height: 75vh !important;
  }

  .max-vh-md-100 {
    max-height: 100vh !important;
  }

  .border-md-x {
    border-left: 1px solid var(--reign-border-color) !important;
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-md-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .border-md-y {
    border-top: 1px solid var(--reign-border-color) !important;
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-md-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  .border-md-dashed {
    border: 1px dashed var(--reign-border-color) !important;
  }

  .border-md-dashed-top {
    border-top: 1px dashed var(--reign-border-color) !important;
  }

  .border-md-dashed-end {
    border-right: 1px dashed #9ba0a7 !important;
  }

  .border-md-dashed-start {
    border-left: 1px dashed #9ba0a7 !important;
  }

  .border-md-dashed-bottom {
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .border-md-dashed-x {
    border-left: 1px dashed var(--reign-border-color) !important;
    border-right: 1px dashed var(--reign-border-color) !important;
  }

  .border-md-dashed-y {
    border-top: 1px dashed var(--reign-border-color) !important;
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .rounded-md-ts {
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-md-ts-lg {
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-md-ts-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-md-te {
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-md-te-lg {
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-md-te-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-md-bs {
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-md-bs-lg {
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-md-bs-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-md-be {
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-md-be-lg {
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-md-be-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .opacity-lg-0 {
    opacity: 0 !important;
  }

  .opacity-lg-25 {
    opacity: 0.25 !important;
  }

  .opacity-lg-50 {
    opacity: 0.5 !important;
  }

  .opacity-lg-75 {
    opacity: 0.75 !important;
  }

  .opacity-lg-85 {
    opacity: 0.85 !important;
  }

  .opacity-lg-100 {
    opacity: 1 !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }

  .translate-lg-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }

  .translate-lg-middle-x {
    transform: translateX(-50%) !important;
  }

  .translate-lg-middle-y {
    transform: translateY(-50%) !important;
  }

  .border-lg {
    border: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-lg-top {
    border-top: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-top-0 {
    border-top: 0 !important;
  }

  .border-lg-end {
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-end-0 {
    border-right: 0 !important;
  }

  .border-lg-bottom {
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-lg-start {
    border-left: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-start-0 {
    border-left: 0 !important;
  }

  .border-lg-facebook {
    border-color: var(--reign-facebook) !important;
  }

  .border-lg-google-plus {
    border-color: var(--reign-google-plus) !important;
  }

  .border-lg-twitter {
    border-color: var(--reign-twitter) !important;
  }

  .border-lg-linkedin {
    border-color: var(--reign-linkedin) !important;
  }

  .border-lg-youtube {
    border-color: var(--reign-youtube) !important;
  }

  .border-lg-github {
    border-color: var(--reign-github) !important;
  }

  .border-lg-black {
    border-color: var(--reign-black) !important;
  }

  .border-lg-100 {
    border-color: var(--reign-100) !important;
  }

  .border-lg-200 {
    border-color: var(--reign-200) !important;
  }

  .border-lg-300 {
    border-color: var(--reign-300) !important;
  }

  .border-lg-400 {
    border-color: var(--reign-400) !important;
  }

  .border-lg-500 {
    border-color: var(--reign-500) !important;
  }

  .border-lg-600 {
    border-color: var(--reign-600) !important;
  }

  .border-lg-700 {
    border-color: var(--reign-700) !important;
  }

  .border-lg-800 {
    border-color: var(--reign-800) !important;
  }

  .border-lg-900 {
    border-color: var(--reign-900) !important;
  }

  .border-lg-1000 {
    border-color: var(--reign-1000) !important;
  }

  .border-lg-1100 {
    border-color: var(--reign-1100) !important;
  }

  .border-lg-white {
    border-color: var(--reign-white) !important;
  }

  .border-lg-primary {
    border-color: var(--reign-primary) !important;
  }

  .border-lg-secondary {
    border-color: var(--reign-secondary) !important;
  }

  .border-lg-success {
    border-color: var(--reign-success) !important;
  }

  .border-lg-info {
    border-color: var(--reign-info) !important;
  }

  .border-lg-warning {
    border-color: var(--reign-warning) !important;
  }

  .border-lg-danger {
    border-color: var(--reign-danger) !important;
  }

  .border-lg-light {
    border-color: var(--reign-light) !important;
  }

  .border-lg-dark {
    border-color: var(--reign-dark) !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .vw-lg-25 {
    width: 25vw !important;
  }

  .vw-lg-50 {
    width: 50vw !important;
  }

  .vw-lg-75 {
    width: 75vw !important;
  }

  .vw-lg-100 {
    width: 100vw !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .vh-lg-25 {
    height: 25vh !important;
  }

  .vh-lg-50 {
    height: 50vh !important;
  }

  .vh-lg-75 {
    height: 75vh !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .min-vh-lg-25 {
    min-height: 25vh !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .min-vh-lg-100 {
    min-height: 100vh !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.3125rem !important;
  }

  .gap-lg-2 {
    gap: 0.625rem !important;
  }

  .gap-lg-3 {
    gap: 1.25rem !important;
  }

  .gap-lg-4 {
    gap: 2.5rem !important;
  }

  .gap-lg-5 {
    gap: 3.75rem !important;
  }

  .gap-lg-6 {
    gap: 5rem !important;
  }

  .gap-lg-7 {
    gap: 6.25rem !important;
  }

  .gap-lg-8 {
    gap: 7.5rem !important;
  }

  .gap-lg-9 {
    gap: 8.75rem !important;
  }

  .gap-lg-10 {
    gap: 10rem !important;
  }

  .gap-lg-11 {
    gap: 11.25rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.3125rem !important;
  }

  .m-lg-2 {
    margin: 0.625rem !important;
  }

  .m-lg-3 {
    margin: 1.25rem !important;
  }

  .m-lg-4 {
    margin: 2.5rem !important;
  }

  .m-lg-5 {
    margin: 3.75rem !important;
  }

  .m-lg-6 {
    margin: 5rem !important;
  }

  .m-lg-7 {
    margin: 6.25rem !important;
  }

  .m-lg-8 {
    margin: 7.5rem !important;
  }

  .m-lg-9 {
    margin: 8.75rem !important;
  }

  .m-lg-10 {
    margin: 10rem !important;
  }

  .m-lg-11 {
    margin: 11.25rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .mx-lg-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-7 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }

  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-11 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }

  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .my-lg-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-7 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-lg-9 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }

  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-11 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-6 {
    margin-top: 5rem !important;
  }

  .mt-lg-7 {
    margin-top: 6.25rem !important;
  }

  .mt-lg-8 {
    margin-top: 7.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 8.75rem !important;
  }

  .mt-lg-10 {
    margin-top: 10rem !important;
  }

  .mt-lg-11 {
    margin-top: 11.25rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }

  .me-lg-2 {
    margin-right: 0.625rem !important;
  }

  .me-lg-3 {
    margin-right: 1.25rem !important;
  }

  .me-lg-4 {
    margin-right: 2.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3.75rem !important;
  }

  .me-lg-6 {
    margin-right: 5rem !important;
  }

  .me-lg-7 {
    margin-right: 6.25rem !important;
  }

  .me-lg-8 {
    margin-right: 7.5rem !important;
  }

  .me-lg-9 {
    margin-right: 8.75rem !important;
  }

  .me-lg-10 {
    margin-right: 10rem !important;
  }

  .me-lg-11 {
    margin-right: 11.25rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6.25rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 7.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 8.75rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 11.25rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }

  .ms-lg-3 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-4 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-6 {
    margin-left: 5rem !important;
  }

  .ms-lg-7 {
    margin-left: 6.25rem !important;
  }

  .ms-lg-8 {
    margin-left: 7.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 8.75rem !important;
  }

  .ms-lg-10 {
    margin-left: 10rem !important;
  }

  .ms-lg-11 {
    margin-left: 11.25rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.3125rem !important;
  }

  .m-lg-n2 {
    margin: -0.625rem !important;
  }

  .m-lg-n3 {
    margin: -1.25rem !important;
  }

  .m-lg-n4 {
    margin: -2.5rem !important;
  }

  .m-lg-n5 {
    margin: -3.75rem !important;
  }

  .m-lg-n6 {
    margin: -5rem !important;
  }

  .m-lg-n7 {
    margin: -6.25rem !important;
  }

  .m-lg-n8 {
    margin: -7.5rem !important;
  }

  .m-lg-n9 {
    margin: -8.75rem !important;
  }

  .m-lg-n10 {
    margin: -10rem !important;
  }

  .m-lg-n11 {
    margin: -11.25rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n4 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .mx-lg-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }

  .mx-lg-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n11 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .my-lg-n3 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n4 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n7 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .my-lg-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }

  .my-lg-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n11 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n4 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n6 {
    margin-top: -5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -6.25rem !important;
  }

  .mt-lg-n8 {
    margin-top: -7.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -8.75rem !important;
  }

  .mt-lg-n10 {
    margin-top: -10rem !important;
  }

  .mt-lg-n11 {
    margin-top: -11.25rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }

  .me-lg-n3 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n4 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n6 {
    margin-right: -5rem !important;
  }

  .me-lg-n7 {
    margin-right: -6.25rem !important;
  }

  .me-lg-n8 {
    margin-right: -7.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -8.75rem !important;
  }

  .me-lg-n10 {
    margin-right: -10rem !important;
  }

  .me-lg-n11 {
    margin-right: -11.25rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -6.25rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -7.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -8.75rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -11.25rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n4 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n6 {
    margin-left: -5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -6.25rem !important;
  }

  .ms-lg-n8 {
    margin-left: -7.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -8.75rem !important;
  }

  .ms-lg-n10 {
    margin-left: -10rem !important;
  }

  .ms-lg-n11 {
    margin-left: -11.25rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.3125rem !important;
  }

  .p-lg-2 {
    padding: 0.625rem !important;
  }

  .p-lg-3 {
    padding: 1.25rem !important;
  }

  .p-lg-4 {
    padding: 2.5rem !important;
  }

  .p-lg-5 {
    padding: 3.75rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .p-lg-7 {
    padding: 6.25rem !important;
  }

  .p-lg-8 {
    padding: 7.5rem !important;
  }

  .p-lg-9 {
    padding: 8.75rem !important;
  }

  .p-lg-10 {
    padding: 10rem !important;
  }

  .p-lg-11 {
    padding: 11.25rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }

  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .px-lg-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-7 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-lg-9 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }

  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-11 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }

  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .py-lg-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-7 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-lg-9 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }

  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-11 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }

  .pt-lg-7 {
    padding-top: 6.25rem !important;
  }

  .pt-lg-8 {
    padding-top: 7.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 8.75rem !important;
  }

  .pt-lg-10 {
    padding-top: 10rem !important;
  }

  .pt-lg-11 {
    padding-top: 11.25rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }

  .pe-lg-3 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-4 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-6 {
    padding-right: 5rem !important;
  }

  .pe-lg-7 {
    padding-right: 6.25rem !important;
  }

  .pe-lg-8 {
    padding-right: 7.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 8.75rem !important;
  }

  .pe-lg-10 {
    padding-right: 10rem !important;
  }

  .pe-lg-11 {
    padding-right: 11.25rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6.25rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 7.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 8.75rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 11.25rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }

  .ps-lg-3 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-4 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-6 {
    padding-left: 5rem !important;
  }

  .ps-lg-7 {
    padding-left: 6.25rem !important;
  }

  .ps-lg-8 {
    padding-left: 7.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 8.75rem !important;
  }

  .ps-lg-10 {
    padding-left: 10rem !important;
  }

  .ps-lg-11 {
    padding-left: 11.25rem !important;
  }

  .fs-lg--2 {
    font-size: 0.6076388889rem !important;
  }

  .fs-lg--1 {
    font-size: 0.7291666667rem !important;
  }

  .fs-lg-0 {
    font-size: 0.875rem !important;
  }

  .fs-lg-1 {
    font-size: 1.2rem !important;
  }

  .fs-lg-2 {
    font-size: 1.44rem !important;
  }

  .fs-lg-3 {
    font-size: 1.728rem !important;
  }

  .fs-lg-4 {
    font-size: 2.0736rem !important;
  }

  .fs-lg-5 {
    font-size: 2.48832rem !important;
  }

  .fs-lg-6 {
    font-size: 2.985984rem !important;
  }

  .fs-lg-7 {
    font-size: 3.5831808rem !important;
  }

  .fs-lg-8 {
    font-size: 4.29981696rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .rounded-lg-top {
    border-top-left-radius: 0.188rem !important;
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-lg-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-lg-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-lg-end {
    border-top-right-radius: 0.188rem !important;
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-lg-end-lg {
    border-top-right-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-lg-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-lg-bottom {
    border-bottom-right-radius: 0.188rem !important;
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-lg-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-lg-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-lg-start {
    border-bottom-left-radius: 0.188rem !important;
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-lg-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-lg-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .max-vh-lg-25 {
    max-height: 25vh !important;
  }

  .max-vh-lg-50 {
    max-height: 50vh !important;
  }

  .max-vh-lg-75 {
    max-height: 75vh !important;
  }

  .max-vh-lg-100 {
    max-height: 100vh !important;
  }

  .border-lg-x {
    border-left: 1px solid var(--reign-border-color) !important;
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .border-lg-y {
    border-top: 1px solid var(--reign-border-color) !important;
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-lg-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  .border-lg-dashed {
    border: 1px dashed var(--reign-border-color) !important;
  }

  .border-lg-dashed-top {
    border-top: 1px dashed var(--reign-border-color) !important;
  }

  .border-lg-dashed-end {
    border-right: 1px dashed #9ba0a7 !important;
  }

  .border-lg-dashed-start {
    border-left: 1px dashed #9ba0a7 !important;
  }

  .border-lg-dashed-bottom {
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .border-lg-dashed-x {
    border-left: 1px dashed var(--reign-border-color) !important;
    border-right: 1px dashed var(--reign-border-color) !important;
  }

  .border-lg-dashed-y {
    border-top: 1px dashed var(--reign-border-color) !important;
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .rounded-lg-ts {
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-lg-ts-lg {
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-lg-ts-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-lg-te {
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-lg-te-lg {
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-lg-te-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-lg-bs {
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-lg-bs-lg {
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-lg-bs-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-lg-be {
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-lg-be-lg {
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-lg-be-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .opacity-xl-0 {
    opacity: 0 !important;
  }

  .opacity-xl-25 {
    opacity: 0.25 !important;
  }

  .opacity-xl-50 {
    opacity: 0.5 !important;
  }

  .opacity-xl-75 {
    opacity: 0.75 !important;
  }

  .opacity-xl-85 {
    opacity: 0.85 !important;
  }

  .opacity-xl-100 {
    opacity: 1 !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }

  .translate-xl-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }

  .translate-xl-middle-x {
    transform: translateX(-50%) !important;
  }

  .translate-xl-middle-y {
    transform: translateY(-50%) !important;
  }

  .border-xl {
    border: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-xl-top {
    border-top: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-top-0 {
    border-top: 0 !important;
  }

  .border-xl-end {
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-end-0 {
    border-right: 0 !important;
  }

  .border-xl-bottom {
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-xl-start {
    border-left: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-start-0 {
    border-left: 0 !important;
  }

  .border-xl-facebook {
    border-color: var(--reign-facebook) !important;
  }

  .border-xl-google-plus {
    border-color: var(--reign-google-plus) !important;
  }

  .border-xl-twitter {
    border-color: var(--reign-twitter) !important;
  }

  .border-xl-linkedin {
    border-color: var(--reign-linkedin) !important;
  }

  .border-xl-youtube {
    border-color: var(--reign-youtube) !important;
  }

  .border-xl-github {
    border-color: var(--reign-github) !important;
  }

  .border-xl-black {
    border-color: var(--reign-black) !important;
  }

  .border-xl-100 {
    border-color: var(--reign-100) !important;
  }

  .border-xl-200 {
    border-color: var(--reign-200) !important;
  }

  .border-xl-300 {
    border-color: var(--reign-300) !important;
  }

  .border-xl-400 {
    border-color: var(--reign-400) !important;
  }

  .border-xl-500 {
    border-color: var(--reign-500) !important;
  }

  .border-xl-600 {
    border-color: var(--reign-600) !important;
  }

  .border-xl-700 {
    border-color: var(--reign-700) !important;
  }

  .border-xl-800 {
    border-color: var(--reign-800) !important;
  }

  .border-xl-900 {
    border-color: var(--reign-900) !important;
  }

  .border-xl-1000 {
    border-color: var(--reign-1000) !important;
  }

  .border-xl-1100 {
    border-color: var(--reign-1100) !important;
  }

  .border-xl-white {
    border-color: var(--reign-white) !important;
  }

  .border-xl-primary {
    border-color: var(--reign-primary) !important;
  }

  .border-xl-secondary {
    border-color: var(--reign-secondary) !important;
  }

  .border-xl-success {
    border-color: var(--reign-success) !important;
  }

  .border-xl-info {
    border-color: var(--reign-info) !important;
  }

  .border-xl-warning {
    border-color: var(--reign-warning) !important;
  }

  .border-xl-danger {
    border-color: var(--reign-danger) !important;
  }

  .border-xl-light {
    border-color: var(--reign-light) !important;
  }

  .border-xl-dark {
    border-color: var(--reign-dark) !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .vw-xl-25 {
    width: 25vw !important;
  }

  .vw-xl-50 {
    width: 50vw !important;
  }

  .vw-xl-75 {
    width: 75vw !important;
  }

  .vw-xl-100 {
    width: 100vw !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .vh-xl-25 {
    height: 25vh !important;
  }

  .vh-xl-50 {
    height: 50vh !important;
  }

  .vh-xl-75 {
    height: 75vh !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .min-vh-xl-25 {
    min-height: 25vh !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .min-vh-xl-100 {
    min-height: 100vh !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.3125rem !important;
  }

  .gap-xl-2 {
    gap: 0.625rem !important;
  }

  .gap-xl-3 {
    gap: 1.25rem !important;
  }

  .gap-xl-4 {
    gap: 2.5rem !important;
  }

  .gap-xl-5 {
    gap: 3.75rem !important;
  }

  .gap-xl-6 {
    gap: 5rem !important;
  }

  .gap-xl-7 {
    gap: 6.25rem !important;
  }

  .gap-xl-8 {
    gap: 7.5rem !important;
  }

  .gap-xl-9 {
    gap: 8.75rem !important;
  }

  .gap-xl-10 {
    gap: 10rem !important;
  }

  .gap-xl-11 {
    gap: 11.25rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.3125rem !important;
  }

  .m-xl-2 {
    margin: 0.625rem !important;
  }

  .m-xl-3 {
    margin: 1.25rem !important;
  }

  .m-xl-4 {
    margin: 2.5rem !important;
  }

  .m-xl-5 {
    margin: 3.75rem !important;
  }

  .m-xl-6 {
    margin: 5rem !important;
  }

  .m-xl-7 {
    margin: 6.25rem !important;
  }

  .m-xl-8 {
    margin: 7.5rem !important;
  }

  .m-xl-9 {
    margin: 8.75rem !important;
  }

  .m-xl-10 {
    margin: 10rem !important;
  }

  .m-xl-11 {
    margin: 11.25rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }

  .mx-xl-3 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-7 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }

  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }

  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-11 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }

  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .my-xl-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-7 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }

  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-xl-9 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }

  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-11 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-6 {
    margin-top: 5rem !important;
  }

  .mt-xl-7 {
    margin-top: 6.25rem !important;
  }

  .mt-xl-8 {
    margin-top: 7.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 8.75rem !important;
  }

  .mt-xl-10 {
    margin-top: 10rem !important;
  }

  .mt-xl-11 {
    margin-top: 11.25rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }

  .me-xl-2 {
    margin-right: 0.625rem !important;
  }

  .me-xl-3 {
    margin-right: 1.25rem !important;
  }

  .me-xl-4 {
    margin-right: 2.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3.75rem !important;
  }

  .me-xl-6 {
    margin-right: 5rem !important;
  }

  .me-xl-7 {
    margin-right: 6.25rem !important;
  }

  .me-xl-8 {
    margin-right: 7.5rem !important;
  }

  .me-xl-9 {
    margin-right: 8.75rem !important;
  }

  .me-xl-10 {
    margin-right: 10rem !important;
  }

  .me-xl-11 {
    margin-right: 11.25rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6.25rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 7.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 8.75rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 11.25rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }

  .ms-xl-3 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-4 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-6 {
    margin-left: 5rem !important;
  }

  .ms-xl-7 {
    margin-left: 6.25rem !important;
  }

  .ms-xl-8 {
    margin-left: 7.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 8.75rem !important;
  }

  .ms-xl-10 {
    margin-left: 10rem !important;
  }

  .ms-xl-11 {
    margin-left: 11.25rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.3125rem !important;
  }

  .m-xl-n2 {
    margin: -0.625rem !important;
  }

  .m-xl-n3 {
    margin: -1.25rem !important;
  }

  .m-xl-n4 {
    margin: -2.5rem !important;
  }

  .m-xl-n5 {
    margin: -3.75rem !important;
  }

  .m-xl-n6 {
    margin: -5rem !important;
  }

  .m-xl-n7 {
    margin: -6.25rem !important;
  }

  .m-xl-n8 {
    margin: -7.5rem !important;
  }

  .m-xl-n9 {
    margin: -8.75rem !important;
  }

  .m-xl-n10 {
    margin: -10rem !important;
  }

  .m-xl-n11 {
    margin: -11.25rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n4 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }

  .mx-xl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }

  .mx-xl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n11 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .my-xl-n3 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n4 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n7 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }

  .my-xl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }

  .my-xl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n11 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n4 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n6 {
    margin-top: -5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -6.25rem !important;
  }

  .mt-xl-n8 {
    margin-top: -7.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -8.75rem !important;
  }

  .mt-xl-n10 {
    margin-top: -10rem !important;
  }

  .mt-xl-n11 {
    margin-top: -11.25rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }

  .me-xl-n3 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n4 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n6 {
    margin-right: -5rem !important;
  }

  .me-xl-n7 {
    margin-right: -6.25rem !important;
  }

  .me-xl-n8 {
    margin-right: -7.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -8.75rem !important;
  }

  .me-xl-n10 {
    margin-right: -10rem !important;
  }

  .me-xl-n11 {
    margin-right: -11.25rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -6.25rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -7.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -8.75rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -11.25rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n4 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n6 {
    margin-left: -5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -6.25rem !important;
  }

  .ms-xl-n8 {
    margin-left: -7.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -8.75rem !important;
  }

  .ms-xl-n10 {
    margin-left: -10rem !important;
  }

  .ms-xl-n11 {
    margin-left: -11.25rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.3125rem !important;
  }

  .p-xl-2 {
    padding: 0.625rem !important;
  }

  .p-xl-3 {
    padding: 1.25rem !important;
  }

  .p-xl-4 {
    padding: 2.5rem !important;
  }

  .p-xl-5 {
    padding: 3.75rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .p-xl-7 {
    padding: 6.25rem !important;
  }

  .p-xl-8 {
    padding: 7.5rem !important;
  }

  .p-xl-9 {
    padding: 8.75rem !important;
  }

  .p-xl-10 {
    padding: 10rem !important;
  }

  .p-xl-11 {
    padding: 11.25rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }

  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }

  .px-xl-3 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-7 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }

  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-xl-9 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }

  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-11 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }

  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .py-xl-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-7 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }

  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-xl-9 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }

  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-11 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-6 {
    padding-top: 5rem !important;
  }

  .pt-xl-7 {
    padding-top: 6.25rem !important;
  }

  .pt-xl-8 {
    padding-top: 7.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 8.75rem !important;
  }

  .pt-xl-10 {
    padding-top: 10rem !important;
  }

  .pt-xl-11 {
    padding-top: 11.25rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }

  .pe-xl-3 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-4 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-6 {
    padding-right: 5rem !important;
  }

  .pe-xl-7 {
    padding-right: 6.25rem !important;
  }

  .pe-xl-8 {
    padding-right: 7.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 8.75rem !important;
  }

  .pe-xl-10 {
    padding-right: 10rem !important;
  }

  .pe-xl-11 {
    padding-right: 11.25rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6.25rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 7.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 8.75rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 11.25rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }

  .ps-xl-3 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-4 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-6 {
    padding-left: 5rem !important;
  }

  .ps-xl-7 {
    padding-left: 6.25rem !important;
  }

  .ps-xl-8 {
    padding-left: 7.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 8.75rem !important;
  }

  .ps-xl-10 {
    padding-left: 10rem !important;
  }

  .ps-xl-11 {
    padding-left: 11.25rem !important;
  }

  .fs-xl--2 {
    font-size: 0.6076388889rem !important;
  }

  .fs-xl--1 {
    font-size: 0.7291666667rem !important;
  }

  .fs-xl-0 {
    font-size: 0.875rem !important;
  }

  .fs-xl-1 {
    font-size: 1.2rem !important;
  }

  .fs-xl-2 {
    font-size: 1.44rem !important;
  }

  .fs-xl-3 {
    font-size: 1.728rem !important;
  }

  .fs-xl-4 {
    font-size: 2.0736rem !important;
  }

  .fs-xl-5 {
    font-size: 2.48832rem !important;
  }

  .fs-xl-6 {
    font-size: 2.985984rem !important;
  }

  .fs-xl-7 {
    font-size: 3.5831808rem !important;
  }

  .fs-xl-8 {
    font-size: 4.29981696rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .rounded-xl-top {
    border-top-left-radius: 0.188rem !important;
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-xl-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-xl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-xl-end {
    border-top-right-radius: 0.188rem !important;
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-xl-end-lg {
    border-top-right-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-xl-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-xl-bottom {
    border-bottom-right-radius: 0.188rem !important;
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-xl-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-xl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-xl-start {
    border-bottom-left-radius: 0.188rem !important;
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-xl-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-xl-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .max-vh-xl-25 {
    max-height: 25vh !important;
  }

  .max-vh-xl-50 {
    max-height: 50vh !important;
  }

  .max-vh-xl-75 {
    max-height: 75vh !important;
  }

  .max-vh-xl-100 {
    max-height: 100vh !important;
  }

  .border-xl-x {
    border-left: 1px solid var(--reign-border-color) !important;
    border-right: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .border-xl-y {
    border-top: 1px solid var(--reign-border-color) !important;
    border-bottom: 1px solid var(--reign-border-color) !important;
  }

  .border-xl-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  .border-xl-dashed {
    border: 1px dashed var(--reign-border-color) !important;
  }

  .border-xl-dashed-top {
    border-top: 1px dashed var(--reign-border-color) !important;
  }

  .border-xl-dashed-end {
    border-right: 1px dashed #9ba0a7 !important;
  }

  .border-xl-dashed-start {
    border-left: 1px dashed #9ba0a7 !important;
  }

  .border-xl-dashed-bottom {
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .border-xl-dashed-x {
    border-left: 1px dashed var(--reign-border-color) !important;
    border-right: 1px dashed var(--reign-border-color) !important;
  }

  .border-xl-dashed-y {
    border-top: 1px dashed var(--reign-border-color) !important;
    border-bottom: 1px dashed var(--reign-border-color) !important;
  }

  .rounded-xl-ts {
    border-top-left-radius: 0.188rem !important;
  }

  .rounded-xl-ts-lg {
    border-top-left-radius: 0.625rem !important;
  }

  .rounded-xl-ts-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-xl-te {
    border-top-right-radius: 0.188rem !important;
  }

  .rounded-xl-te-lg {
    border-top-right-radius: 0.625rem !important;
  }

  .rounded-xl-te-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-xl-bs {
    border-bottom-left-radius: 0.188rem !important;
  }

  .rounded-xl-bs-lg {
    border-bottom-left-radius: 0.625rem !important;
  }

  .rounded-xl-bs-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-xl-be {
    border-bottom-right-radius: 0.188rem !important;
  }

  .rounded-xl-be-lg {
    border-bottom-right-radius: 0.625rem !important;
  }

  .rounded-xl-be-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: var(--reign-400);
}

::selection {
  background-color: #dc3545;
  color: #fff;
}

html {
  scroll-padding-top: 5.625rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

pre,
code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  scroll-margin-top: -1.5625rem;
}
@media (min-width: 768px) {
  section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

pre {
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500 !important;
  margin-bottom: 0.625rem;
}

pre,
code,
kbd,
samp {
  font-size: 0.875rem !important;
}

ol {
  padding-left: 3.125rem;
}

small, .small {
  font-weight: 300 !important;
}

/* -------------------------------------------------------------------------- */
/*                                   Navbar                                   */
/* -------------------------------------------------------------------------- */
.navbar-toggler:focus {
  box-shadow: none;
}

/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  font-weight: 400;
  font-size: 0.7rem;
  letter-spacing: 0.01em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  min-height: 3.125rem;
}

.navbar-brand-i {
  background-color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0;
  padding: 0.25rem 0.625rem;
  margin: 0 0.3125rem;
  border-radius: 0.125rem;
  display: inline-block;
}

.nav-link {
  position: relative;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  line-height: 1.2 !important;
}

.navbar-theme {
  background-color: rgba(255, 255, 255, 0);
}
.navbar-theme .navbar-nav > .nav-item > .nav-link {
  position: relative;
  transition: padding 0.4s ease;
}
.navbar-theme .navbar-nav > .nav-item .nav-link-text {
  padding: 0.0625rem 0.25rem;
  transition: all 0.4s ease-in-out;
}
.navbar-theme .navbar-nav > .nav-item > .nav-link:active > .nav-link-text,
.navbar-theme .navbar-nav > .nav-item > .nav-link.active > .nav-link-text,
.navbar-theme .navbar-nav > .nav-item > .nav-link:hover > .nav-link-text, .navbar-theme .navbar-nav > .nav-item.show > .nav-link > .nav-link-text {
  background-color: #fff;
  color: #000;
  border-radius: 0.125rem;
}

.navbar-theme .navbar-collapse.show {
  max-height: calc(100vh - 4.063rem);
  overflow-y: auto;
}

.dropdown-components {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
@media (min-width: 992px) {
  .dropdown-components {
    min-width: 37rem;
  }
}

.dropdown-pages {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
@media (min-width: 992px) {
  .dropdown-pages {
    min-width: 24rem;
  }
}

.navbar-expand-xs .navbar-theme {
  min-height: 4rem;
}
.navbar-expand-xs .navbar-brand {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
}
.navbar-expand-xs .navbar-collapse.show {
  overflow: visible;
  max-height: none;
}
.navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
  padding: 1.375rem 1.625rem;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-theme {
    min-height: 4rem;
  }
  .navbar-expand-sm .navbar-brand {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .navbar-expand-sm .navbar-collapse.show {
    overflow: visible;
    max-height: none;
  }
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    padding: 1.375rem 1.625rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-theme {
    min-height: 4rem;
  }
  .navbar-expand-md .navbar-brand {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .navbar-expand-md .navbar-collapse.show {
    overflow: visible;
    max-height: none;
  }
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    padding: 1.375rem 1.625rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-theme {
    min-height: 4rem;
  }
  .navbar-expand-lg .navbar-brand {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .navbar-expand-lg .navbar-collapse.show {
    overflow: visible;
    max-height: none;
  }
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    padding: 1.375rem 1.625rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-theme {
    min-height: 4rem;
  }
  .navbar-expand-xl .navbar-brand {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .navbar-expand-xl .navbar-collapse.show {
    overflow: visible;
    max-height: none;
  }
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    padding: 1.375rem 1.625rem;
  }
}
/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */
.badge-soft-primary {
  color: var(--reign-badge-soft-primary-color);
  background-color: var(--reign-badge-soft-primary-background-color);
}
.badge-soft-primary[href]:hover, .badge-soft-primary[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-primary-color);
}

.badge-soft-secondary {
  color: var(--reign-badge-soft-secondary-color);
  background-color: var(--reign-badge-soft-secondary-background-color);
}
.badge-soft-secondary[href]:hover, .badge-soft-secondary[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-secondary-color);
}

.badge-soft-success {
  color: var(--reign-badge-soft-success-color);
  background-color: var(--reign-badge-soft-success-background-color);
}
.badge-soft-success[href]:hover, .badge-soft-success[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-success-color);
}

.badge-soft-info {
  color: var(--reign-badge-soft-info-color);
  background-color: var(--reign-badge-soft-info-background-color);
}
.badge-soft-info[href]:hover, .badge-soft-info[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-info-color);
}

.badge-soft-warning {
  color: var(--reign-badge-soft-warning-color);
  background-color: var(--reign-badge-soft-warning-background-color);
}
.badge-soft-warning[href]:hover, .badge-soft-warning[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-warning-color);
}

.badge-soft-danger {
  color: var(--reign-badge-soft-danger-color);
  background-color: var(--reign-badge-soft-danger-background-color);
}
.badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-danger-color);
}

.badge-soft-light {
  color: var(--reign-badge-soft-light-color);
  background-color: var(--reign-badge-soft-light-background-color);
}
.badge-soft-light[href]:hover, .badge-soft-light[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-light-color);
}

.badge-soft-dark {
  color: var(--reign-badge-soft-dark-color);
  background-color: var(--reign-badge-soft-dark-background-color);
}
.badge-soft-dark[href]:hover, .badge-soft-dark[href]:focus {
  text-decoration: none;
  color: var(--reign-badge-soft-dark-color);
}

.nav-tabs {
  border-bottom: 2px solid var(--reign-800);
}
.nav-tabs .nav-link {
  margin-bottom: -2px;
  border: var(--reign-border-color);
  font-size: 0.875rem;
  border-top-left-radius: 0.188rem;
  border-top-right-radius: 0.188rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: var(--reign-white);
}
.nav-tabs .nav-link.disabled {
  color: var(--reign-black);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border-bottom: 2px solid var(--reign-white);
}

/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */
.firefox .dropcap:first-letter {
  margin-top: 0.175em;
}
.firefox .card-notification .scrollbar-overlay {
  min-height: 19rem;
}

.chrome .btn-close {
  background-clip: unset;
}

/*-----------------------------------------------
|   Buttons
-----------------------------------------------*/
.btn {
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.25rem 1.5rem;
  font-size: 0.72888rem;
  line-height: 1.273;
  border-radius: 0.1875rem;
}

.btn-link {
  font-weight: 700;
  text-transform: none;
}

.btn.btn-outline-light.border-2x {
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-outline-light:hover,
.btn-light:hover {
  color: #17191b;
  background-color: #e2e2e2;
  border-color: #dcdcdc;
}

.btn-xs {
  padding: 0.2rem 1rem;
  font-size: 0.765625rem;
}

.btn-play {
  color: #fff;
  border: 1px solid #fff;
  width: 3.75rem;
  height: 3.75rem;
  position: relative;
  display: block;
  line-height: 3.75rem;
  border-radius: 0.188rem;
}
.btn-play:hover, .btn-play:focus {
  color: #000 !important;
}

.btn-dark {
  color: #dadcdf;
}

.btn-back-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 999;
  background: #eee;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  line-height: 1.75rem;
  font-size: 0.875rem;
  color: #000;
  opacity: 0.6;
  border-radius: 2px;
}

.btn-back-to-top:hover {
  color: #000;
  opacity: 1;
}

/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
:not(pre) > code[class*=language-], pre[class*=language-] {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

code[class*=language-], pre[class*=language-] {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.contains-anchor a {
  opacity: 0;
  transition-property: opacity;
}
.contains-anchor:hover a, .contains-anchor:focus a {
  opacity: 1;
  text-decoration: none;
}

.components-nav {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.707rem;
  font-weight: 500;
  letter-spacing: 0.01rem;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.components-nav li a {
  color: #9ba0a7;
}

.component-preview {
  border: 0.125rem solid #f5f5f5;
  border-radius: 0.188rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.component-example [class^=border] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin: 0.75rem;
  background-color: #f5f5f5;
}

/*-----------------------------------------------
|   Grid
-----------------------------------------------*/
.grid-component [class^=col] {
  border: 1px solid #c8cbcf;
  text-align: center;
  margin-bottom: 0.625rem;
  color: #777e87;
  padding: 0.5rem;
  font-size: 0.7291666667rem;
}

/*-----------------------------------------------
|   Prism
-----------------------------------------------*/
.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #2f6f9f;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #4f9fcf;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #d44950;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  background: transparent;
}

/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index--1 {
  z-index: -1 !important;
}

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: 1015;
  top: 6.625rem;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/
.hover-text-decoration-none:hover, .hover-text-decoration-none:focus {
  text-decoration: none;
}

.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: all 0.4s ease-in-out;
  transform: rotate(0deg);
}

.collapse-icon {
  transition: all 0.4s ease-in-out;
  transform: rotate(90deg);
}

[data-dismiss=dropdown] *,
[data-bs-offset-top] *,
[data-bs-toggle=collapse] *,
[data-bs-toggle=tooltip] *,
[data-bs-toggle=popover] * {
  pointer-events: none;
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/
.outline-none {
  outline: none;
  box-shadow: none;
}
.outline-none:hover, .outline-none:focus {
  outline: none;
  box-shadow: none;
}

/*-----------------------------------------------
|   Vertical Line
-----------------------------------------------*/
@media (min-width: 992px) {
  .vertical-line {
    position: absolute;
    width: 1px;
    height: 50%;
    right: 0;
    background-color: var(--reign-border-color);
    top: 50%;
    transform: translateY(-50%);
  }
}
/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */
.transition-base {
  transition: all 0.4s ease-in-out;
}

.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */
.min-w-0 {
  min-width: 0;
}

.blockquote-footer {
  font-weight: 700;
  display: block;
  font-size: 75%;
  color: #b6babf;
}

.opacity-95 {
  opacity: 0.95;
}

/* -------------------------------------------------------------------------- */
/*                                  Anchor JS                                 */
/* -------------------------------------------------------------------------- */
.anchorjs-link {
  text-decoration: none !important;
}

/* -------------------------------------------------------------------------- */
/*                                    Plyr                                    */
/* -------------------------------------------------------------------------- */
.plyr {
  border-radius: 0.625rem;
}

.plyr__control--overlaid, .plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  background-color: #dc3545;
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true], .plyr__menu__container label.plyr__control input[type=radio]:checked + span {
  background-color: #dc3545;
}

.plyr--full-ui input[type=range] {
  color: #dc3545;
}

/* -------------------------------------------------------------------------- */
/*                                  Glightbox                                 */
/* -------------------------------------------------------------------------- */
.glightbox-open {
  overflow: unset !important;
}

.gscrollbar-fixer {
  margin-right: 0 !important;
}

/* -------------------------------------------------------------------------- */
/*                                 Google Map                                 */
/* -------------------------------------------------------------------------- */
.googlemap .gm-style-iw.gm-style-iw-c {
  box-shadow: var(--reign-box-shadow) !important;
  padding: 1rem !important;
}
.googlemap .gm-style-iw.gm-style-iw-c button[title=Close] {
  margin-top: 1rem !important;
  margin-right: 0.5rem !important;
}

html[dir=rtl] .googlemap .gm-style-iw.gm-style-iw-c button[title=Close] {
  left: unset !important;
  right: 0 !important;
}

.theme-slider .swiper-nav {
  margin-top: 0;
  cursor: pointer;
}
.theme-slider .swiper-nav [class*=swiper-] {
  opacity: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  transition: opacity 0.4s ease-in-out;
  color: var(--reign-100);
  z-index: 1;
  margin: 0;
}
.theme-slider .swiper-nav [class*=swiper-]:hover, .theme-slider .swiper-nav [class*=swiper-]:focus {
  color: var(--reign-white);
  opacity: 1 !important;
}
.theme-slider .swiper-nav .swiper-button-prev {
  left: 0.625rem;
}
.theme-slider .swiper-nav .swiper-button-next {
  right: 0.625rem;
}
.theme-slider:hover .swiper-nav [class*=swiper-], .theme-slider:focus .swiper-nav [class*=swiper-] {
  opacity: 0.5;
}
.theme-slider .swiper-button-prev:after,
.theme-slider .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.product-slider {
  position: relative;
  height: calc(100% - 4.75rem);
}
@media (min-width: 992px) {
  .product-slider {
    height: calc(100% - 3.5625rem);
  }
}
@media (min-width: 1200px) {
  .product-slider {
    height: calc(100% - 3.3125rem);
  }
}

.header-slider {
  height: 100vh;
  width: 100%;
  filter: brightness(0.7);
  object-fit: cover;
}

.slider-classic {
  height: 50vh;
  width: 100%;
  filter: brightness(0.99);
  object-fit: cover;
}

.header-classic-slider {
  height: 50vh;
  width: 100%;
  filter: brightness(0.7);
  object-fit: cover;
}

@media (min-width: 992px) {
  .swiper-container .swiper-nav {
    margin-top: 0;
    cursor: pointer;
  }
  .swiper-container .swiper-nav .swiper-button-prev, .swiper-container .swiper-nav .swiper-button-next {
    opacity: 0;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    transition: opacity 0.4s ease-in-out;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: var(--reign-100);
    border: 1px solid #fff;
    box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin: 0;
  }
  .swiper-container .swiper-nav .swiper-button-prev:hover, .swiper-container .swiper-nav .swiper-button-prev:focus, .swiper-container .swiper-nav .swiper-button-next:hover, .swiper-container .swiper-nav .swiper-button-next:focus {
    color: var(--reign-white);
    opacity: 1 !important;
  }
  .swiper-container .swiper-nav .swiper-button-next {
    right: -1.25rem;
  }
  .swiper-container .swiper-nav .swiper-button-prev {
    left: -1.25rem;
  }
  .swiper-container:hover .swiper-nav [class*=swiper-], .swiper-container:focus .swiper-nav [class*=swiper-] {
    opacity: 0.5;
  }
  .swiper-container .swiper-button-prev:after,
.swiper-container .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
}

.swiper-container.swiper-clients {
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
}

.swiper-pagination-bullet {
  margin: 0.625rem;
  background: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid #fff;
  font-size: 1rem;
}
.swiper-pagination-bullet:focus {
  outline: unset;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-pagination {
  margin-top: 1.25rem;
  position: unset !important;
  text-align: center;
}

/*-----------------------------------------------
|   Prism
-----------------------------------------------*/
:not(pre) > code[class*=language-], pre[class*=language-] {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

code[class*=language-], pre[class*=language-] {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.contains-anchor a {
  opacity: 0;
  transition-property: opacity;
}
.contains-anchor:hover a, .contains-anchor:focus a {
  opacity: 1;
  text-decoration: none;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #2f6f9f;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #4f9fcf;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #d44950;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  background: transparent;
}

/*-----------------------------------------------
|   Isotope
-----------------------------------------------*/
.isotope-nav {
  font-weight: 700;
  border: 1px solid #fff;
  font-size: 0.765625rem;
  font-weight: 400;
  color: var(--reign-black);
  border-radius: 0.188rem;
  letter-spacing: 0.02em;
  margin-right: 0.25rem;
  padding: 0.3125rem 0.625rem;
}
.isotope-nav.active {
  color: #000 !important;
  border: 1px solid var(--reign-black);
}
.isotope-nav:hover {
  color: var(--reign-black);
  text-decoration: none;
}

.sortable .menu {
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.7291666667rem;
  letter-spacing: 0.13rem;
}
.sortable .menu .isotope-nav {
  cursor: pointer;
  padding: 0.3125rem 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid transparent;
  transition: all 0.4s ease-in-out;
  margin-right: 0.3125rem;
}
.sortable .menu .isotope-nav:last-child {
  margin-right: 0;
}
.sortable .menu .isotope-nav.active {
  border-color: currentColor;
  color: #000;
  border-radius: 0.188rem;
}

.sortable-container.sortable-container-gutter-fix {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.work-alt-sticky-nav {
  padding-top: 6.25rem;
  margin-top: -6.25rem;
}

/*-----------------------------------------------
|   Progress bar and circle
-----------------------------------------------*/
.progress-circle {
  height: 15.625rem;
  position: relative;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.progress-circle .progressbar-text {
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.progress-circle .progressbar-text span {
  font-size: 0.875rem;
  display: block;
}
.progress-circle .progressbar-text .value {
  font-weight: 300;
  font-size: 2.48832rem;
}
.progress-circle .progressbar-text b {
  font-size: 2.0736rem;
  font-weight: 300;
}
.progress-circle svg {
  height: 100%;
  display: block;
}

.progress-line {
  position: relative;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}
.progress-line .progressbar-text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 2px;
  padding: 0;
  margin: 0;
}
.progress-line .progressbar-text .value {
  right: 0;
  position: absolute;
}
.progress-line:not(:last-child) {
  margin-bottom: 1.125rem;
}
@media (min-width: 768px) {
  .progress-line:not(:last-child) {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .progress-line:not(:last-child) {
    margin-bottom: 1.125rem;
  }
}

.hoverdir-item {
  position: relative;
  overflow: hidden;
}
.hoverdir-item .hoverdir-text {
  position: absolute;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

/*-----------------------------------------------
|   Drop cap
-----------------------------------------------*/
.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: 0.92;
  margin-right: 0.375rem;
}

/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls {
  letter-spacing: 0.125rem;
}

/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside {
  list-style-position: inside;
}

.style-check li {
  position: relative;
  list-style-type: none;
  padding-left: 0.25rem;
}
.style-check li:before {
  content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1mYS1wcm9jZXNzZWQ9IiIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNCIgc3R5bGU9ImZvbnQtc2l6ZTogNDhweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxMy41MDUgOTEuOTUxTDEzMy40OSAzNzEuOTY2bC05OC45OTUtOTguOTk1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNi4yMTEgMjg0LjI4NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDExOC43OTQgMTE4Ljc5NGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsMjk5LjgxMy0yOTkuODEzYzQuNjg2LTQuNjg2IDQuNjg2LTEyLjI4NCAwLTE2Ljk3MWwtMTEuMzE0LTExLjMxNGMtNC42ODYtNC42ODYtMTIuMjg0LTQuNjg2LTE2Ljk3IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
  padding-right: 0.1875rem;
  position: absolute;
  top: 0.0625rem;
  left: -1rem;
  background-repeat: no-repeat;
  width: 1rem;
}

/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/
.blockquote-footer::before {
  transform: translateY(0.125rem);
  font-weight: 400;
  display: inline-block;
}

/*-----------------------------------------------
|   Horizontal rules
-----------------------------------------------*/
.hr-short {
  width: 5rem;
  margin: 1.25rem auto;
}

/*-----------------------------------------------
|   Headings
-----------------------------------------------*/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
}

h1, .h1 {
  font-weight: 300;
}

h2, h3, h4, .h2, .h3, .h4 {
  font-weight: 200;
}

h5, .h5 {
  font-weight: 400;
}

h6, .h6 {
  font-weight: 500;
}

h2, .h2, h3, .h3 {
  line-height: 2.5rem;
}

h4, .h4, h5, .h5 {
  line-height: 1.875rem;
}

h6, .h6 {
  line-height: 1.25rem;
}

.display-3 {
  letter-spacing: 0.25rem;
}

/*-----------------------------------------------
|   Cursor
-----------------------------------------------*/
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */
.text-smallcaps {
  font-variant: small-caps;
}

.text-superscript {
  vertical-align: super;
}

.text-word-break {
  word-break: break-word;
}

.text-transform-none {
  text-transform: none;
}

/*-----------------------------------------------
|   Font family
-----------------------------------------------*/
.font-sans-serif {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-base {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*-----------------------------------------------
|   Error Page
-----------------------------------------------*/
.fs-error {
  font-size: 7rem;
}
@media (min-width: 576px) {
  .fs-error {
    font-size: 10rem;
  }
}

/*-----------------------------------------------
|   Text alignment
-----------------------------------------------*/
.text-justify {
  text-align: justify !important;
}

/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}
.bg-holder.bg-right {
  left: auto;
  right: 0;
}
.bg-holder.overlay:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-holder.overlay-primary:before {
  background: rgba(0, 123, 255, 0.4);
}
.bg-holder.overlay-info:before {
  background: rgba(23, 162, 184, 0.4);
}
.bg-holder.overlay-success:before {
  background: rgba(40, 167, 69, 0.4);
}
.bg-holder.overlay-warning:before {
  background: rgba(255, 193, 7, 0.4);
}
.bg-holder.overlay-danger:before {
  background: rgba(220, 53, 69, 0.4);
}
.bg-holder.overlay-0:before {
  background: rgba(0, 0, 0, 0.7);
}
.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.55);
}
.bg-holder.overlay-2:before {
  background: rgba(0, 0, 0, 0.4);
}
.bg-holder.overlay-gradient:before {
  background: linear-gradient(-45deg, rgba(0, 25, 66, 0.702) 0, rgba(244, 244, 234, 0.5) 100%);
}
.bg-holder .bg-video {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}
.bg-holder .bg-youtube {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-glass {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-card {
  background-size: contain;
  background-position: right;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
[dir=rtl] .bg-card {
  background-position: left;
  transform: scaleX(-1);
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.header-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mb_YTPBar {
  display: none !important;
}

.ytp-chrome-top {
  display: none !important;
  visibility: hidden !important;
}

.ytp-title {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */
.overflow-hidden[class*=rounded] {
  mask-image: radial-gradient(#fff, #000);
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-end-2 {
  border-right-width: 2px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-start-2 {
  border-left-width: 2px !important;
}

/* -------------------------------------------------------------------------- */
/*                                  Position                                  */
/* -------------------------------------------------------------------------- */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  position: relative;
}

.all-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}

.flex-between-end {
  align-items: flex-end;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.link-primary {
  color: var(--reign-primary);
}
.link-primary:hover, .link-primary:focus {
  color: var(--reign-colored-link-primary-hover-color);
}

.link-secondary {
  color: var(--reign-secondary);
}
.link-secondary:hover, .link-secondary:focus {
  color: var(--reign-colored-link-secondary-hover-color);
}

.link-success {
  color: var(--reign-success);
}
.link-success:hover, .link-success:focus {
  color: var(--reign-colored-link-success-hover-color);
}

.link-info {
  color: var(--reign-info);
}
.link-info:hover, .link-info:focus {
  color: var(--reign-colored-link-info-hover-color);
}

.link-warning {
  color: var(--reign-warning);
}
.link-warning:hover, .link-warning:focus {
  color: var(--reign-colored-link-warning-hover-color);
}

.link-danger {
  color: var(--reign-danger);
}
.link-danger:hover, .link-danger:focus {
  color: var(--reign-colored-link-danger-hover-color);
}

.link-light {
  color: var(--reign-light);
}
.link-light:hover, .link-light:focus {
  color: var(--reign-colored-link-light-hover-color);
}

.link-dark {
  color: var(--reign-dark);
}
.link-dark:hover, .link-dark:focus {
  color: var(--reign-colored-link-dark-hover-color);
}

.link-black {
  color: var(--reign-black) !important;
}
.link-black:hover, .link-black:focus {
  color: var(--reign-colored-link-black-hover-color) !important;
}

.link-100 {
  color: var(--reign-100) !important;
}
.link-100:hover, .link-100:focus {
  color: var(--reign-colored-link-100-hover-color) !important;
}

.link-200 {
  color: var(--reign-200) !important;
}
.link-200:hover, .link-200:focus {
  color: var(--reign-colored-link-200-hover-color) !important;
}

.link-300 {
  color: var(--reign-300) !important;
}
.link-300:hover, .link-300:focus {
  color: var(--reign-colored-link-300-hover-color) !important;
}

.link-400 {
  color: var(--reign-400) !important;
}
.link-400:hover, .link-400:focus {
  color: var(--reign-colored-link-400-hover-color) !important;
}

.link-500 {
  color: var(--reign-500) !important;
}
.link-500:hover, .link-500:focus {
  color: var(--reign-colored-link-500-hover-color) !important;
}

.link-600 {
  color: var(--reign-600) !important;
}
.link-600:hover, .link-600:focus {
  color: var(--reign-colored-link-600-hover-color) !important;
}

.link-700 {
  color: var(--reign-700) !important;
}
.link-700:hover, .link-700:focus {
  color: var(--reign-colored-link-700-hover-color) !important;
}

.link-800 {
  color: var(--reign-800) !important;
}
.link-800:hover, .link-800:focus {
  color: var(--reign-colored-link-800-hover-color) !important;
}

.link-900 {
  color: var(--reign-900) !important;
}
.link-900:hover, .link-900:focus {
  color: var(--reign-colored-link-900-hover-color) !important;
}

.link-1000 {
  color: var(--reign-1000) !important;
}
.link-1000:hover, .link-1000:focus {
  color: var(--reign-colored-link-1000-hover-color) !important;
}

.link-1100 {
  color: var(--reign-1100) !important;
}
.link-1100:hover, .link-1100:focus {
  color: var(--reign-colored-link-1100-hover-color) !important;
}

.link-white {
  color: var(--reign-white) !important;
}
.link-white:hover, .link-white:focus {
  color: var(--reign-colored-link-white-hover-color) !important;
}

/*-----------------------------------------------
|   Dropdowns
-----------------------------------------------*/
.dropdown-menu {
  font-size: 0.7rem;
}

.dropdown-item {
  transition: all 0.4s ease-in-out;
  font-weight: 400;
  border-bottom: 1px solid rgba(73, 71, 71, 0.15);
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.dropdown-toggle:after {
  position: absolute;
  vertical-align: middle;
  margin-left: 0.375rem;
  top: 50%;
  transform: translateY(-50%);
  border-top: 0.4em solid;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
}

.dropdown-item:last-child {
  border-bottom: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #000;
}
.dropdown-item:hover:first-child, .dropdown-item:focus:first-child {
  border-top-right-radius: 0.188rem;
  border-top-left-radius: 0.188rem;
}
.dropdown-item:hover:last-child, .dropdown-item:focus:last-child {
  border-bottom-right-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}

.dropdown-divider {
  width: 2rem;
  margin: 1rem auto;
}
.dropdown-divider + .dropdown-item {
  border-top: 1px solid #17191b;
}

/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.scrollbar-overlay {
  overflow: hidden !important;
}

.scrollbar {
  overflow: auto;
}
.scrollbar::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 0.375rem;
  height: 0.375rem;
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: var(--reign-scrollbar-bg);
}
.scrollbar:hover::-webkit-scrollbar, .scrollbar:hover::-webkit-scrollbar-thumb, .scrollbar:focus::-webkit-scrollbar, .scrollbar:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}

.navbar-vibrant .scrollbar {
  overflow: auto;
}
.navbar-vibrant .scrollbar::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 0.375rem;
  height: 0.375rem;
  background-color: transparent;
}
.navbar-vibrant .scrollbar::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.4);
}
.navbar-vibrant .scrollbar:hover::-webkit-scrollbar, .navbar-vibrant .scrollbar:hover::-webkit-scrollbar-thumb, .navbar-vibrant .scrollbar:focus::-webkit-scrollbar, .navbar-vibrant .scrollbar:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}

.firefox .scrollbar {
  overflow: auto !important;
  scrollbar-color: var(--reign-scrollbar-bg) transparent;
  scrollbar-width: thin;
}
.firefox .navbar-vibrant .scrollbar {
  overflow: auto !important;
  scrollbar-color: rgba(255, 255, 255, 0.4) transparent;
  scrollbar-width: thin;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: var(--reign-scrollbar-bg);
}
.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-dark > .os-scrollbar:focus > .os-scrollbar-track > .os-scrollbar-handle {
  background: var(--reign-scrollbar-bg);
}

/*-----------------------------------------------
|   Showcase
-----------------------------------------------*/
.hover-underline-none:hover {
  text-decoration: none;
}

.quote.showcase-quote:before {
  color: #000;
  opacity: 0.2;
}

.showcase-item-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.5s ease;
}
.showcase-item-content:hover, .showcase-item-content:focus {
  opacity: 1;
}

.avatar-showcase-testimonial {
  position: absolute;
  width: 6.25rem !important;
  height: 6.25rem;
  left: 50%;
  transform: translate(-50%, -127%);
  background-size: cover;
  backface-visibility: hidden;
  transition: all 0.4s ease-in;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 6.25rem;
  background-color: #525B83;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.box-shadow-md {
  box-shadow: 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}

.shadow-md {
  box-shadow: 0px 0px 2.6875rem 0px rgba(22, 31, 99, 0.1);
}

.hover-shadow-soft {
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}
.hover-shadow-soft:hover, .hover-shadow-soft:focus {
  transform: translateY(-0.5rem);
  box-shadow: 0 0 5.25rem 0 rgba(22, 31, 99, 0.15);
}

/* -------------------------------------------------------------------------- */
/*                                  Accordion                                 */
/* -------------------------------------------------------------------------- */
.accordion-button {
  text-transform: uppercase;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3e%3c/svg%3e");
  transform: rotate(90deg);
}
.accordion-button::after {
  display: none;
}
.accordion-button::before {
  flex-shrink: 0;
  width: 0.6rem;
  height: 0.6rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0.6rem;
  transition: transform 0.2s ease-in-out;
  margin-right: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::before {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.btn[class*=hvr-] {
  position: relative;
  overflow: hidden;
  z-index: 0;
  transform: perspective(1px) translateZ(0);
  background-color: transparent;
  vertical-align: middle;
}
.btn[class*=hvr-]:hover, .btn[class*=hvr-]:focus {
  color: #fff;
}
.btn[class*=hvr-]:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: transform 0.3s ease-in-out;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  backface-visibility: hidden;
}
.btn.hvr-sweep-top:after {
  transform: scaleY(1);
  transform-origin: 50% 100%;
}
.btn.hvr-sweep-bottom:after {
  transform: scaleY(1);
  transform-origin: 50% 0%;
}
.btn.hvr-sweep-left:after {
  transform: scaleX(1);
  transform-origin: 100% 0%;
}
.btn.hvr-sweep-right:after {
  transform: scaleX(1);
  transform-origin: 0% 0%;
}
.btn.hvr-sweep-collapseX:after {
  transform: scaleX(0);
  transform-origin: 50%;
}
.btn.hvr-sweep-collapseY:after {
  transform: scaleY(0);
  transform-origin: 50%;
}
.btn:active.hvr-sweep-top:after, .btn:hover.hvr-sweep-top:after, .btn:focus.hvr-sweep-top:after {
  transform: scaleY(0);
}
.btn:active.hvr-sweep-bottom:after, .btn:hover.hvr-sweep-bottom:after, .btn:focus.hvr-sweep-bottom:after {
  transform: scaleY(0);
}
.btn:active.hvr-sweep-left:after, .btn:hover.hvr-sweep-left:after, .btn:focus.hvr-sweep-left:after {
  transform: scaleX(0);
}
.btn:active.hvr-sweep-right:after, .btn:hover.hvr-sweep-right:after, .btn:focus.hvr-sweep-right:after {
  transform: scaleX(0);
}
.btn:active.hvr-sweep-collapseX:after, .btn:hover.hvr-sweep-collapseX:after, .btn:focus.hvr-sweep-collapseX:after {
  transform: scaleX(1);
}
.btn:active.hvr-sweep-collapseY:after, .btn:hover.hvr-sweep-collapseY:after, .btn:focus.hvr-sweep-collapseY:after {
  transform: scaleY(1);
}
.btn[class*=btn-outline-].hvr-sweep-top:after {
  transform-origin: 50% 0;
  transform: scaleY(0);
}
.btn[class*=btn-outline-].hvr-sweep-bottom:after {
  transform-origin: 50% 100%;
  transform: scaleY(0);
}
.btn[class*=btn-outline-].hvr-sweep-left:after {
  transform-origin: 0% 0%;
  transform: scaleX(0);
}
.btn[class*=btn-outline-].hvr-sweep-right:after {
  transform-origin: 100% 0%;
  transform: scaleX(0);
}
.btn[class*=btn-outline-].hvr-sweep-collapseX:after {
  transform: scaleX(1);
  transform-origin: 50%;
}
.btn[class*=btn-outline-].hvr-sweep-collapseY:after {
  transform: scaleY(1);
  transform-origin: 50%;
}
.btn[class*=btn-outline-]:active.hvr-sweep-top:after, .btn[class*=btn-outline-]:hover.hvr-sweep-top:after, .btn[class*=btn-outline-]:focus.hvr-sweep-top:after {
  transform: scaleY(1);
}
.btn[class*=btn-outline-]:active.hvr-sweep-bottom:after, .btn[class*=btn-outline-]:hover.hvr-sweep-bottom:after, .btn[class*=btn-outline-]:focus.hvr-sweep-bottom:after {
  transform: scaleY(1);
}
.btn[class*=btn-outline-]:active.hvr-sweep-left:after, .btn[class*=btn-outline-]:hover.hvr-sweep-left:after, .btn[class*=btn-outline-]:focus.hvr-sweep-left:after {
  transform: scaleX(1);
}
.btn[class*=btn-outline-]:active.hvr-sweep-right:after, .btn[class*=btn-outline-]:hover.hvr-sweep-right:after, .btn[class*=btn-outline-]:focus.hvr-sweep-right:after {
  transform: scaleX(1);
}
.btn[class*=btn-outline-]:active.hvr-sweep-collapseX:after, .btn[class*=btn-outline-]:hover.hvr-sweep-collapseX:after, .btn[class*=btn-outline-]:focus.hvr-sweep-collapseX:after {
  transform: scaleX(0);
}
.btn[class*=btn-outline-]:active.hvr-sweep-collapseY:after, .btn[class*=btn-outline-]:hover.hvr-sweep-collapseY:after, .btn[class*=btn-outline-]:focus.hvr-sweep-collapseY:after {
  transform: scaleY(0);
}
.btn[class*=btn-outline-].hvr-sweep-link:after {
  height: 2px;
  top: auto;
  transform: translateX(-102%);
}
.btn[class*=btn-outline-]:hover.hvr-sweep-link, .btn[class*=btn-outline-]:focus.hvr-sweep-link {
  box-shadow: none;
}
.btn[class*=btn-outline-]:hover.hvr-sweep-link:after, .btn[class*=btn-outline-]:focus.hvr-sweep-link:after {
  transform: translateX(0);
}

.btn-outline-white[class*=hvr-]:after, .btn-outline-white[class*=hvr-]:before {
  background-color: #fff;
}

.btn-outline-light[class*=hvr-]:hover, .btn-outline-light[class*=hvr-]:focus {
  color: #000;
}

.btn-outline-primary:before, .btn-outline-primary:after {
  background-color: #007bff;
}
.btn-outline-primary.hvr-sweep-collapseX, .btn-outline-primary.hvr-sweep-collapseY {
  background-color: #007bff;
}
.btn-outline-primary.hvr-sweep-collapseX:after, .btn-outline-primary.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-primary.hvr-sweep-collapseX, .btn-primary.hvr-sweep-collapseY {
  background-color: #007bff;
}
.btn-primary.hvr-sweep-collapseX:after, .btn-primary.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-primary:after {
  background-color: #007bff;
}
.btn-primary[class*=hvr-]:hover, .btn-primary[class*=hvr-]:focus {
  color: #007bff;
}
.btn-primary[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-secondary:before, .btn-outline-secondary:after {
  background-color: #777e87;
}
.btn-outline-secondary.hvr-sweep-collapseX, .btn-outline-secondary.hvr-sweep-collapseY {
  background-color: #777e87;
}
.btn-outline-secondary.hvr-sweep-collapseX:after, .btn-outline-secondary.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-secondary.hvr-sweep-collapseX, .btn-secondary.hvr-sweep-collapseY {
  background-color: #777e87;
}
.btn-secondary.hvr-sweep-collapseX:after, .btn-secondary.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-secondary:after {
  background-color: #777e87;
}
.btn-secondary[class*=hvr-]:hover, .btn-secondary[class*=hvr-]:focus {
  color: #777e87;
}
.btn-secondary[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-success:before, .btn-outline-success:after {
  background-color: #28a745;
}
.btn-outline-success.hvr-sweep-collapseX, .btn-outline-success.hvr-sweep-collapseY {
  background-color: #28a745;
}
.btn-outline-success.hvr-sweep-collapseX:after, .btn-outline-success.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-success.hvr-sweep-collapseX, .btn-success.hvr-sweep-collapseY {
  background-color: #28a745;
}
.btn-success.hvr-sweep-collapseX:after, .btn-success.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-success:after {
  background-color: #28a745;
}
.btn-success[class*=hvr-]:hover, .btn-success[class*=hvr-]:focus {
  color: #28a745;
}
.btn-success[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-info:before, .btn-outline-info:after {
  background-color: #17a2b8;
}
.btn-outline-info.hvr-sweep-collapseX, .btn-outline-info.hvr-sweep-collapseY {
  background-color: #17a2b8;
}
.btn-outline-info.hvr-sweep-collapseX:after, .btn-outline-info.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-info.hvr-sweep-collapseX, .btn-info.hvr-sweep-collapseY {
  background-color: #17a2b8;
}
.btn-info.hvr-sweep-collapseX:after, .btn-info.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-info:after {
  background-color: #17a2b8;
}
.btn-info[class*=hvr-]:hover, .btn-info[class*=hvr-]:focus {
  color: #17a2b8;
}
.btn-info[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-warning:before, .btn-outline-warning:after {
  background-color: #ffc107;
}
.btn-outline-warning.hvr-sweep-collapseX, .btn-outline-warning.hvr-sweep-collapseY {
  background-color: #ffc107;
}
.btn-outline-warning.hvr-sweep-collapseX:after, .btn-outline-warning.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-warning.hvr-sweep-collapseX, .btn-warning.hvr-sweep-collapseY {
  background-color: #ffc107;
}
.btn-warning.hvr-sweep-collapseX:after, .btn-warning.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-warning:after {
  background-color: #ffc107;
}
.btn-warning[class*=hvr-]:hover, .btn-warning[class*=hvr-]:focus {
  color: #ffc107;
}
.btn-warning[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-danger:before, .btn-outline-danger:after {
  background-color: #dc3545;
}
.btn-outline-danger.hvr-sweep-collapseX, .btn-outline-danger.hvr-sweep-collapseY {
  background-color: #dc3545;
}
.btn-outline-danger.hvr-sweep-collapseX:after, .btn-outline-danger.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-danger.hvr-sweep-collapseX, .btn-danger.hvr-sweep-collapseY {
  background-color: #dc3545;
}
.btn-danger.hvr-sweep-collapseX:after, .btn-danger.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-danger:after {
  background-color: #dc3545;
}
.btn-danger[class*=hvr-]:hover, .btn-danger[class*=hvr-]:focus {
  color: #dc3545;
}
.btn-danger[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-light:before, .btn-outline-light:after {
  background-color: #f5f5f5;
}
.btn-outline-light.hvr-sweep-collapseX, .btn-outline-light.hvr-sweep-collapseY {
  background-color: #f5f5f5;
}
.btn-outline-light.hvr-sweep-collapseX:after, .btn-outline-light.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-light.hvr-sweep-collapseX, .btn-light.hvr-sweep-collapseY {
  background-color: #f5f5f5;
}
.btn-light.hvr-sweep-collapseX:after, .btn-light.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-light:after {
  background-color: #f5f5f5;
}
.btn-light[class*=hvr-]:hover, .btn-light[class*=hvr-]:focus {
  color: #f5f5f5;
}
.btn-light[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-dark:before, .btn-outline-dark:after {
  background-color: #17191b;
}
.btn-outline-dark.hvr-sweep-collapseX, .btn-outline-dark.hvr-sweep-collapseY {
  background-color: #17191b;
}
.btn-outline-dark.hvr-sweep-collapseX:after, .btn-outline-dark.hvr-sweep-collapseY:after {
  background-color: #fff;
}

.btn-dark.hvr-sweep-collapseX, .btn-dark.hvr-sweep-collapseY {
  background-color: #17191b;
}
.btn-dark.hvr-sweep-collapseX:after, .btn-dark.hvr-sweep-collapseY:after {
  background-color: #fff;
}
.btn-dark:after {
  background-color: #17191b;
}
.btn-dark[class*=hvr-]:hover, .btn-dark[class*=hvr-]:focus {
  color: #17191b;
}
.btn-dark[class*=hvr-]:active {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-outline-white:hover .play-icon, .btn-outline-white:focus .play-icon {
  stroke: #000;
  transition-delay: 0.1s;
}

.hover-sweep-top {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.hover-sweep-top:after {
  position: absolute;
  content: "";
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.hover-sweep-top:hover, .hover-sweep-top:focus {
  background-color: transparent;
}
.hover-sweep-top:hover:after, .hover-sweep-top:focus:after {
  top: 0;
}
.hover-sweep-top:hover *, .hover-sweep-top:focus * {
  color: #000 !important;
}

.hvr-underline-none:hover {
  text-decoration: none;
}

a.hover-color-white {
  transition: all 0.2s ease;
}
a.hover-color-white:hover {
  color: #f5f5f5 !important;
}

/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;
}
.hoverbox .hoverbox-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  margin: 0 !important;
}
.hoverbox .hoverbox-primary-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}
.hoverbox:hover .hover-box-content-initial, .hoverbox:focus .hover-box-content-initial {
  opacity: 0;
}
.hoverbox:hover .hoverbox-content, .hoverbox:focus .hoverbox-content {
  opacity: 1;
}
.hoverbox:hover .as-hoverbox-content, .hoverbox:focus .as-hoverbox-content {
  z-index: 1;
}

.hoverbox-content-gradient {
  background: linear-gradient(transparent, #000);
}

.hoverbox-background {
  background-color: rgba(0, 0, 0, 0.8);
}

/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
}
.icon-group .icon-item:not(:last-child) {
  margin-right: 0.625rem;
}

.icon-item {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--reign-700);
  transition: all 0.4s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  position: relative;
  font-size: 0.765625rem;
}
.icon-item:hover, .icon-item:focus {
  background-color: var(--reign-200);
}
.icon-item.icon-item-sm {
  height: 1.875rem;
  width: 1.875rem;
}
.icon-item.icon-item-lg {
  height: 3.125rem;
  width: 3.125rem;
}

.fa-icon-wait {
  opacity: 0;
}
.fontawesome-i2svg-active .fa-icon-wait {
  opacity: 1;
}

.ring-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  height: 6.25rem;
  width: 6.25rem;
  border-radius: 50%;
  border: 1px solid var(--reign-border-color);
  font-size: 1.728rem;
  color: #007bff;
}

.brand-icon {
  background: #007bff;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.188rem;
  height: 2.5rem;
  width: 2.5rem;
  color: #fff;
}

/* -------------------------------------------------------------------------- */
/*                             Bootstrap Carousel                             */
/* -------------------------------------------------------------------------- */
.theme-slider:hover .carousel-control-next,
.theme-slider:hover .carousel-control-prev, .theme-slider:focus .carousel-control-next,
.theme-slider:focus .carousel-control-prev {
  color: #777e87;
  opacity: 0.5 !important;
}
.theme-slider .carousel-control-prev,
.theme-slider .carousel-control-next {
  width: 3rem;
  height: 3rem;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: opacity 0.4s ease-in-out;
  color: #777e87;
  opacity: 0;
}
.theme-slider .carousel-control-prev:hover, .theme-slider .carousel-control-prev:focus,
.theme-slider .carousel-control-next:hover,
.theme-slider .carousel-control-next:focus {
  opacity: 1 !important;
}
.theme-slider .carousel-control-prev {
  left: 0.625rem;
}
.theme-slider .carousel-control-next {
  right: 0.625rem;
}

/*-----------------------------------------------
|   Input with icon
-----------------------------------------------*/
.input-iconic {
  position: relative;
}
.input-iconic button {
  outline: 0;
  background-color: transparent;
  color: #9ba0a7;
  border: 0;
}
.input-iconic .input-icon {
  position: absolute;
  z-index: 99;
  right: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.input-iconic .form-control {
  padding-right: 3.75rem;
  border-color: #dadcdf;
}

.custom-control-label {
  line-height: 1rem;
}

.custom-file-label,
.custom-file-label:after {
  line-height: 1.25rem;
}

.contact-message {
  height: 11.375rem !important;
}

.breadcrumb {
  font-size: 0.7rem;
  padding: 0.75rem 0;
}

.breadcrumb-item + .breadcrumb-item:before {
  position: relative;
  top: -0.0625rem;
}

/*-----------------------------------------------
|   Google Map
-----------------------------------------------*/
.googlemap {
  min-height: 18.75rem;
}
.googlemap > .marker-content {
  opacity: 0;
}

.yt-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -100000;
  pointer-events: none;
}

iframe.yt-video {
  opacity: 0;
}
iframe.yt-video.loaded {
  opacity: 1;
}

a.footer-link {
  transition: all 0.2s ease;
  color: #5f656c;
}
a.footer-link:hover {
  color: #f5f5f5;
}

/*-----------------------------------------------
|   Preloader
-----------------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: black;
  opacity: 1;
  transition: opacity 0.8s cubic-bezier(0.77, 0, 0.18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.375rem;
}
.preloader.loaded {
  opacity: 0;
}

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
}

.preloader-wrapper.small {
  width: 2.25rem;
  height: 2.25rem;
}

.preloader-wrapper.big {
  width: 4rem;
  height: 4rem;
}

.preloader-wrapper.active {
  animation: container-rotate 1568ms linear infinite;
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a;
}

.spinner-white, .spinner-white-only {
  border-color: #fff;
}

.spinner-blue, .spinner-blue-only {
  border-color: #4285f4;
}

.spinner-red, .spinner-red-only {
  border-color: #db4437;
}

.spinner-yellow, .spinner-yellow-only {
  border-color: #f4b400;
}

.spinner-green, .spinner-green-only {
  border-color: #0f9d58;
}

.active .spinner-layer.spinner-white {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, white-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-blue {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-red {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-yellow {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-green {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer, .active .spinner-layer.spinner-white-only, .active .spinner-layer.spinner-blue-only, .active .spinner-layer.spinner-red-only, .active .spinner-layer.spinner-yellow-only, .active .spinner-layer.spinner-green-only {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes white-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.circle-clipper.left {
  float: left !important;
}
.circle-clipper.right {
  float: right !important;
}

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
  transform: rotate(129deg);
}

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
  transform: rotate(-129deg);
}

.active .circle-clipper.left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@keyframes left-spin {
  from {
    transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
    transform: rotate(130deg);
  }
}
@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
@keyframes right-spin {
  from {
    transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}
#spinnerContainer.cooldown {
  animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.team-item .hoverbox-content {
  background-color: rgba(0, 0, 0, 0.6);
}
.team-item:hover .hoverbox-content, .team-item:focus .hoverbox-content {
  opacity: 1;
}

.service-item {
  border: 1px solid transparent;
  border-radius: 0.188rem;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.service-icon {
  width: 3.5rem;
  margin-bottom: 1.25rem;
}

.service-item:hover {
  border: 1px solid var(--reign-border-color);
}

.process-item {
  position: relative;
}

.process-icon {
  width: 1.563rem;
}

.process-icon-circle {
  position: absolute;
  width: 4rem;
  height: 4rem;
  text-align: center;
  border: 1px dashed #9ba0a7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  left: -2rem;
}

@media (min-width: 768px) {
  .process-title {
    position: relative;
  }
  .process-title:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    height: 1px;
    background-color: #000;
    width: 100%;
    z-index: -1;
  }

  .process-item:nth-child(odd) .media-body {
    padding-right: 3.938rem;
  }
  .process-item:nth-child(odd) .process-icon-circle {
    left: -2.75rem;
  }

  .process-item:nth-child(even) .media-body {
    padding-left: 3.938rem;
  }
  .process-item:nth-child(even) .process-icon-circle {
    right: -2.75rem;
    left: auto;
  }

  .process-devider {
    position: absolute;
    top: 0.125rem;
    height: 0.9375rem;
    width: calc(100% - 2.75rem);
    border: 1px dashed #9ba0a7;
    border-top-width: 0;
    z-index: -1;
  }

  .process-icon-circle {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px dashed #9ba0a7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .process-icon {
    width: 1.875rem;
  }
}
.thumbnail-grid-container.hasOpenItems .collapsed {
  opacity: 0.5;
}
.thumbnail-grid-container .collapse:not(.show) {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}
.thumbnail-grid-container .thumbnail-grid-item {
  transition: all 0.35s ease;
  z-index: 10;
}
.thumbnail-grid-container .thumbnail-grid-navigation .gridder-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background-color: #dadcdf;
  color: #000;
}
.thumbnail-grid-container .thumbnail-grid-navigation .gridder-nav.prev {
  left: 0;
  border-top-right-radius: 0.188rem;
  border-bottom-right-radius: 0.188rem;
}
.thumbnail-grid-container .thumbnail-grid-navigation .gridder-nav.next {
  right: 0;
  border-top-left-radius: 0.188rem;
  border-bottom-left-radius: 0.188rem;
}
.thumbnail-grid-container .thumbnail-grid-navigation .gridder-nav.disabled {
  opacity: 0.5;
}
.thumbnail-grid-container .thumbnail-grid-navigation .thumbnail-close {
  cursor: pointer;
  height: 1.875rem;
  padding: 0.4375rem;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  width: 1.875rem;
  z-index: 2;
  color: #9ba0a7;
}
.thumbnail-grid-container .thumbnail-grid-navigation .thumbnail-grid-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background-color: #dadcdf;
  color: #000;
}
.thumbnail-grid-container .thumbnail-grid-navigation .thumbnail-grid-nav.next {
  right: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.thumbnail-grid-container .thumbnail-grid-navigation .thumbnail-grid-nav.prev {
  left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.thumbnail-grid-container .thumbnail-grid-navigation .thumbnail-grid-nav.disabled {
  opacity: 0.5;
}

.thumbnail-gridder {
  width: 100%;
  border-radius: 0.188rem;
  cursor: pointer;
}
.thumbnail-gridder:hover {
  opacity: 0.8;
}

/*-----------------------------------------------
|   Up/Down Indicator
-----------------------------------------------*/
.indicator-arrow {
  display: block;
  position: absolute;
  left: 50%;
  height: 0.75rem;
  width: 0.75rem;
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  transform: translateX(-50%) rotate(45deg);
}

.indicator-arrow-one {
  top: 1.375rem;
}

.indicator-arrow-two {
  top: 1.75rem;
}

.indicator {
  position: absolute;
  height: 3rem;
  width: 3rem;
  left: 50%;
  z-index: 2;
}

.indicator-up {
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.indicator-down {
  bottom: 2rem;
  transform: translateX(-50%) rotate(180deg);
}

.indicator-arrow-left {
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.indicator-arrow-right {
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(135deg);
}

.checked-indicator {
  display: none;
}

.hr-feature {
  width: 12rem;
  margin: 1.4rem auto;
  opacity: 100;
}

.feature-item:hover .checked-indicator, .feature-item:focus .checked-indicator {
  color: #000;
}

@media (min-width: 992px) {
  .line-indicator:after {
    content: "";
    position: absolute;
    height: 2.25rem;
    right: 2rem;
    border-left: 1px solid var(--reign-border-color);
  }

  .line-indicator-top::after {
    bottom: 0.3rem;
    right: 2.5rem;
    border-top: 1px solid var(--reign-border-color);
  }

  .line-indicator-top-1::after {
    width: 0;
  }

  .line-indicator-top-2::after {
    width: 50%;
  }

  .line-indicator-top-3::after {
    width: 88%;
  }

  .line-indicator-bottom::after {
    top: 0.25rem;
    border-bottom: 1px solid var(--reign-border-color);
  }

  .line-indicator-bottom-1::after {
    width: 36%;
    right: 2.5rem;
  }

  .line-indicator-bottom-2::after {
    width: 15%;
    right: 2.5rem;
  }

  .line-indicator-bottom-3::after {
    width: 50%;
    right: 2.5rem;
  }

  .checked-indicator {
    display: block;
    position: absolute;
    border: 1px solid var(--reign-border-color);
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    background-color: #fff;
    line-height: 2.25rem;
    text-align: center;
    color: #9ba0a7;
    transition: all 0.2s ease;
  }

  .checked-indicator-top {
    transform: translate(-97%, -105%);
  }

  .checked-indicator-bottom {
    transform: translate(-97%, 90%);
  }
}
/*-----------------------------------------------
|   Funfact
-----------------------------------------------*/
.fun-fact-icon {
  width: 2.5rem;
}

@media (min-width: 768px) {
  .fun-fact-icon {
    width: 3.5rem;
  }
}
.swiper-testimonial-container .swiper-nav {
  margin-top: 0;
  cursor: pointer;
}
.swiper-testimonial-container .swiper-nav .swiper-button-prev, .swiper-testimonial-container .swiper-nav .swiper-button-next {
  font-weight: 800;
  position: absolute;
  transform: translateY(-50%);
  transition: opacity 0.4s ease-in-out;
  color: var(--reign-700);
  z-index: 1;
  margin: 0;
}
.swiper-testimonial-container .swiper-nav .swiper-button-prev:hover, .swiper-testimonial-container .swiper-nav .swiper-button-prev:focus, .swiper-testimonial-container .swiper-nav .swiper-button-next:hover, .swiper-testimonial-container .swiper-nav .swiper-button-next:focus {
  color: var(--reign-white);
}
.swiper-testimonial-container .swiper-nav .swiper-button-next {
  right: 7.5rem;
  top: 73%;
}
.swiper-testimonial-container .swiper-nav .swiper-button-prev {
  left: 7.5rem;
  top: 73%;
}
.swiper-testimonial-container .swiper-button-prev:after,
.swiper-testimonial-container .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.news-slider .swiper-pagination-bullet {
  width: 0.625rem;
  height: 0.625rem;
  background: #D6D6D6;
  margin: 0.313rem 0.438rem !important;
  opacity: 1;
}
.news-slider .swiper-pagination-bullet:hover, .news-slider .swiper-pagination-bullet:focus {
  background: #007bff;
}
.news-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #007bff;
}
.news-slider .swiper-horizontal > .swiper-pagination-bullets,
.news-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.news-slider .swiper-pagination-custom,
.news-slider .swiper-pagination-fraction {
  bottom: 0;
}

.header-classic {
  height: 40.438rem;
}
@media (min-width: 576px) {
  .header-classic {
    min-height: 32.313rem;
    height: auto;
  }
}

.testimonial-avatar {
  position: absolute;
  width: 6.25rem !important;
  height: 6.25rem;
  left: 50%;
  transform: translate(-50%, -127%);
  background-size: cover;
  backface-visibility: hidden;
  transition: all 0.4s ease-in;
  background-repeat: no-repeat;
}

.testimonial-quote {
  position: relative;
  line-height: 100%;
  top: 0;
  left: 0;
}
.testimonial-quote:before {
  position: absolute;
  content: "“";
  height: 2rem;
  width: 2rem;
  top: 2.3rem;
  color: #fff;
  font-size: 8rem;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  opacity: 0.5;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial .owl-theme.owl-nav-outer .owl-nav {
  margin-top: 0;
  cursor: pointer;
}
.testimonial .owl-theme.owl-nav-outer .owl-nav [class*=owl-] {
  opacity: 0.5;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  color: #fff !important;
  font-size: 1.5rem !important;
  background-color: transparent !important;
  box-shadow: none;
}
.testimonial .owl-theme.owl-nav-outer .owl-nav [class*=owl-]:hover, .testimonial .owl-theme.owl-nav-outer .owl-nav [class*=owl-]:focus {
  color: #fff;
  opacity: 1 !important;
}
.testimonial .owl-theme.owl-nav-outer .owl-nav .owl-prev {
  left: -8.75rem;
}
.testimonial .owl-theme.owl-nav-outer .owl-nav .owl-next {
  right: -8.75rem;
}
.testimonial .owl-dots {
  bottom: -7.5rem;
}

.testimonial-company-logo {
  width: 6.25rem !important;
  display: inline-block;
  opacity: 0.5;
}

@media (min-width: 768px) {
  .testimonial-quote::before {
    left: -2.5rem;
    transform: initial;
    top: 2rem;
  }
}
.btn-input-icon {
  position: absolute;
  padding: 0.3125rem 0.625rem;
  right: 0;
  top: 0;
  margin-top: 0.5rem;
  margin-right: 0.75rem;
  color: #000;
}

.form-floating {
  color: #9ba0a7 !important;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}