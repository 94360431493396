/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */

@mixin badge-soft-variant($color) {
    color: var(--#{$variable-prefix}badge-soft-#{$color}-color);
    background-color: var(--#{$variable-prefix}badge-soft-#{$color}-background-color);
    &[href] {
      @include hover-focus {
        text-decoration: none;
        color: var(--#{$variable-prefix}badge-soft-#{$color}-color);
      }
    }
  }
  
  @each $color, $value in $theme-colors {
    .badge-soft-#{$color} {
      @include badge-soft-variant($color)
    }
  }