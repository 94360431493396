/*-----------------------------------------------
|   Preloader
-----------------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: black;
  opacity: 1;
  transition: opacity 0.8s cubic-bezier(.77, 0, .18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.375rem;
  &.loaded{
    opacity: 0;
  }
}
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
}

.preloader-wrapper.small {
  width: 2.25rem;
  height: 2.25rem;
}

.preloader-wrapper.big {
  width: 4rem;
  height: 4rem;
}

.preloader-wrapper.active {
  animation: container-rotate 1568ms linear infinite
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #26a69a
}

.spinner-white,.spinner-white-only {
  border-color: #fff
}

.spinner-blue,.spinner-blue-only {
  border-color: #4285f4
}

.spinner-red,.spinner-red-only {
  border-color: #db4437
}

.spinner-yellow,.spinner-yellow-only {
  border-color: #f4b400
}

.spinner-green,.spinner-green-only {
  border-color: #0f9d58
}

.active .spinner-layer.spinner-white {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,white-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer.spinner-blue {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer.spinner-red {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer.spinner-yellow {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer.spinner-green {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer,.active .spinner-layer.spinner-white-only, .active .spinner-layer.spinner-blue-only,.active .spinner-layer.spinner-red-only,.active .spinner-layer.spinner-yellow-only,.active .spinner-layer.spinner-green-only {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg)
  }

  25% {
    -webkit-transform: rotate(270deg)
  }

  37.5% {
    -webkit-transform: rotate(405deg)
  }

  50% {
    -webkit-transform: rotate(540deg)
  }

  62.5% {
    -webkit-transform: rotate(675deg)
  }

  75% {
    -webkit-transform: rotate(810deg)
  }

  87.5% {
    -webkit-transform: rotate(945deg)
  }

  to {
    -webkit-transform: rotate(1080deg)
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
  }

  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }

  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg)
  }

  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg)
  }

  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg)
  }

  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg)
  }

  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg)
  }

  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg)
  }
}

@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1
  }

  25% {
    opacity: 1
  }

  26% {
    opacity: 0
  }

  89% {
    opacity: 0
  }

  90% {
    opacity: 1
  }

  100% {
    opacity: 1
  }
}

@keyframes blue-fade-in-out {
  from {
    opacity: 1
  }

  25% {
    opacity: 1
  }

  26% {
    opacity: 0
  }

  89% {
    opacity: 0
  }

  90% {
    opacity: 1
  }

  100% {
    opacity: 1
  }
}
@keyframes white-fade-in-out {
  from {
    opacity: 0
  }

  15% {
    opacity: 0
  }

  25% {
    opacity: 1
  }

  50% {
    opacity: 1
  }

  51% {
    opacity: 0
  }
}

@keyframes red-fade-in-out {
  from {
    opacity: 0
  }

  15% {
    opacity: 0
  }

  25% {
    opacity: 1
  }

  50% {
    opacity: 1
  }

  51% {
    opacity: 0
  }
}

@keyframes red-fade-in-out {
  from {
    opacity: 0
  }

  15% {
    opacity: 0
  }

  25% {
    opacity: 1
  }

  50% {
    opacity: 1
  }

  51% {
    opacity: 0
  }
}

@keyframes yellow-fade-in-out {
  from {
    opacity: 0
  }

  40% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  75% {
    opacity: 1
  }

  76% {
    opacity: 0
  }
}

@keyframes yellow-fade-in-out {
  from {
    opacity: 0
  }

  40% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  75% {
    opacity: 1
  }

  76% {
    opacity: 0
  }
}

@keyframes green-fade-in-out {
  from {
    opacity: 0
  }

  65% {
    opacity: 0
  }

  75% {
    opacity: 1
  }

  90% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes green-fade-in-out {
  from {
    opacity: 0
  }

  65% {
    opacity: 0
  }

  75% {
    opacity: 1
  }

  90% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit
}

.gap-patch .circle {
  width: 1000%;
  left: -450%
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
  
  &.left { float: left !important }
  &.right { float: right !important }
}

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0
}

.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
  transform: rotate(129deg)
}

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
  transform: rotate(-129deg)
}

.active .circle-clipper.left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .circle-clipper.right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

@keyframes left-spin {
  from {
    transform: rotate(130deg)
  }

  50% {
    transform: rotate(-5deg)
  }

  to {
    transform: rotate(130deg)
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
    transform: rotate(130deg)
  }

  50% {
    transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  to {
    transform: rotate(130deg);
    transform: rotate(130deg)
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg)
  }

  50% {
    transform: rotate(5deg)
  }

  to {
    transform: rotate(-130deg)
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
    transform: rotate(-130deg)
  }

  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg)
  }
}

#spinnerContainer.cooldown {
  animation: container-rotate 1568ms linear infinite,fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1)
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fade-out {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}