/*-----------------------------------------------
|   Funfact
-----------------------------------------------*/

.fun-fact-icon{ 
  width: 2.5rem; 
}

@include media-breakpoint-up(md) {
  .fun-fact-icon{
    width: 3.5rem; 
  }
}