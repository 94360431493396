
.thumbnail-grid-container{
  &.hasOpenItems{
    .collapsed{
      opacity: .5;
    }
  }
  .collapse {
    &:not(.show) {
      display: block !important;
      opacity: 0;
      pointer-events: none;
    }
  }

  .thumbnail-grid-item{
    transition: all 0.35s ease;
    z-index: 10; 
  }

  .thumbnail-grid-navigation{
    .gridder-nav{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0.5rem;
      background-color: $gray-300;
      color: $black;

      &.prev {
        left: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
      &.next {
        right: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
      &.disabled{
          opacity: .5;
      }
    }
    .thumbnail-close {
      cursor: pointer;
      height: 1.875rem;
      padding: 0.4375rem;
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;
      width: 1.875rem;
      z-index: 2;
      color: $gray-600;
    }
  }

  .thumbnail-grid-navigation .thumbnail-grid-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.5rem;
    background-color: #dadcdf;
    color: #000;
    &.next{
      right: 0;
      border-top-left-radius: 0.1875rem;
      border-bottom-left-radius: 0.1875rem;
    }
    &.prev{
      left: 0;
      border-top-left-radius: 0.1875rem;
      border-bottom-left-radius: 0.1875rem;
    }
    &.disabled{
      opacity: .5;
    }
  }
}

.thumbnail-gridder{
  width: 100%;
  border-radius: 0.188rem;
  cursor: pointer;
  &:hover{
    opacity: .8;
  }
}