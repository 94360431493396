
/*-----------------------------------------------
|   Drop cap
-----------------------------------------------*/
.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: .92;
  margin-right: 0.375rem;
}


/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls{ letter-spacing: 0.125rem; }



/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside { list-style-position: inside; }

// Style Check mark
.style-check li {
  position: relative;
  list-style-type: none;
  padding-left: .25rem;
  &:before {
    content: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1mYS1wcm9jZXNzZWQ9IiIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNCIgc3R5bGU9ImZvbnQtc2l6ZTogNDhweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxMy41MDUgOTEuOTUxTDEzMy40OSAzNzEuOTY2bC05OC45OTUtOTguOTk1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNi4yMTEgMjg0LjI4NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDExOC43OTQgMTE4Ljc5NGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsMjk5LjgxMy0yOTkuODEzYzQuNjg2LTQuNjg2IDQuNjg2LTEyLjI4NCAwLTE2Ljk3MWwtMTEuMzE0LTExLjMxNGMtNC42ODYtNC42ODYtMTIuMjg0LTQuNjg2LTE2Ljk3IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
    padding-right: 0.1875rem;
    position: absolute;
    top: 0.0625rem;
    left: -1rem;
    background-repeat: no-repeat;
    width: 1rem;
  }
}

/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/

.blockquote-footer{
  &::before {
    transform: translateY(0.125rem);
    font-weight: $font-weight-normal;
    display: inline-block;
  }
}

/*-----------------------------------------------
|   Horizontal rules
-----------------------------------------------*/
.hr-short{ 
  width: 5rem; 
  margin: 1.25rem auto;
}

// hr {
//   background-color: $primary;
//   border-radius: 0.094rem;
//   margin-left: auto;
//   margin-right: auto;

//   &.short {
//     width: 4.20873rem;
//   }

//   &.muted{
//     height: 0.063rem;
//     border-radius: 0.031rem;
//     opacity: .15;
//   }
// }

// .dropcap:first-letter {
//   color: $primary; 
//   font-weight: $font-weight-semi-bold;
// }

/*-----------------------------------------------
|   Headings
-----------------------------------------------*/

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
}


h1, .h1{
  font-weight: $font-weight-light;
}

h2, h3, h4, .h2, .h3, .h4{
  font-weight: $font-weight-lighter;
}

h5, .h5 {
  font-weight: $font-weight-normal;
}


h6, .h6 {
  font-weight: $font-weight-medium;
}


h2, .h2, h3, .h3{
  line-height: $spacer * 2;
}

h4, .h4, h5, .h5{
  line-height: $spacer * 1.5;
}

h6, .h6{
  line-height: $spacer;
}
.display-3{
  letter-spacing: 0.25rem;
}