//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-100: #f5f5f5  !default;
$gray-200: #ecedee !default;
$gray-300: #dadcdf !default;
$gray-400: #c8cbcf !default;
$gray-500: #b6babf !default;
$gray-600: #9ba0a7 !default;
$gray-700: #777e87 !default;
$gray-800: #5f656c !default;
$gray-900: #4f545a !default;
$gray-1000: #373b3f !default;
$gray-1100: #17191b !default;
$black: #000 !default;


$grays: () !default;
$grays: map-merge(
  (
    'black': $black,
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
    '1000': $gray-1000,
    '1100': $gray-1100,
    'white': $white,
  ),
  $grays
);
// $body-bg: $gray-200 !default;

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);


$dark-grays: (
  'black': $white,
  '100': $gray-1100,
  '200': $gray-1000,
  '300': $gray-900,
  '400': $gray-800,
  '500': $gray-700,
  '600': $gray-600,
  '700': $gray-500,
  '800': $gray-400,
  '900': $gray-300,
  '1000': $gray-200,
  '1100': $gray-100,
  'white': $black,
);
