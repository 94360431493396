/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover{ object-fit: cover; }
.z-index-1{ z-index: 1 !important; }
.z-index-2{ z-index: 2 !important; }
.z-index--1{ z-index: -1 !important; }

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar{ 
  position: sticky;
  z-index: $zindex-sticky;
  top: $top-nav-height + 1; 
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed{
  border: 1px dashed $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none{
  @include hover-focus{
    text-decoration: none;
  }
}
.resize-none{ resize: none; }

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}
.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

[data-dismiss="dropdown"],
[data-bs-offset-top],
[data-bs-toggle="collapse"],
[data-bs-toggle="tooltip"],
[data-bs-toggle="popover"]{
  *{
    pointer-events: none;
  }
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;
  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/*-----------------------------------------------
|   Vertical Line
-----------------------------------------------*/
@include media-breakpoint-up(lg) {
  .vertical-line {
    position: absolute;
    width: 1px;
    height: 50%;
    right: 0;
    background-color: $border-color;
    top: 50%;
    transform: translateY(-50%);
  }
}


/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */

.transition-base {
  transition: $transition-base;
}
.transition-none{
  transition: none;
}

.fsp-75{
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

// used in chat
.min-w-0{ min-width: 0; }


.blockquote-footer {
  font-weight: 700;
  display: block;
  font-size: 75%;
  color: #b6babf;
}


.opacity-95{
  opacity: .95;
}
