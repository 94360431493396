// Hover dir 

.hoverdir-item{
	position: relative;
	overflow: hidden;
	.hoverdir-text {
		position: absolute;
		display: flex;
		color: $white;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(0,0,0,.9);
		border-radius: 5px;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform: translateY(100%);
	}
}

