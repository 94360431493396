.box-shadow-md{
  box-shadow: .25rem .5rem rgba($black, .5);
}
.shadow-md {
  box-shadow: 0px 0px 2.6875rem 0px rgba(22,31,99,0.1);
}

.hover-shadow-soft {
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  transform-style: preserve-3d;

  @include hover-focus {
    transform: translateY(-0.5rem);
    box-shadow: 0 0 5.25rem 0 rgba(22, 31, 99, 0.15);
  }
}